.header-section {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    background: #6a7964;
}

.header-section i {
    font-size: 20px;
    color: #fff;
}
.logo img{
    width: 35px;
    height: 35px;
}
.logo{
    display: flex;
}
.logo h3{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0px !important;
    line-height: 32px;  
}
.sticky-nav {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #FFF;
    z-index: 100;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.promo-code input {
   border-radius: 4px !important;
   margin-right: 10px;
   padding: 5px;
}

.promo-code input:last-child {
    margin-bottom: 0px !important;
}

.promo-code h4 {
    font-size: 14px;
}

.promo-code {
    border: 1px solid gainsboro;
    padding: 10px;
    border-radius: 8px;
}

.promo-code-modal h3 {
    font-size: 16px;
}

.phone-number i {
    margin-right: 10px;
}

.phone-number {
    display: flex;
    gap: 13px;
}

.phone-number p {
    font-size: 16px;
    color: #fff;
    margin-bottom: 0rem;
    font-family: Montserrat;
}
.custom-range::-webkit-slider-thumb {
    /* Styling for the thumb of the range input in WebKit browsers (e.g., Chrome, Safari) */
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: yellow;
    cursor: pointer;
  }

.notification-sms p {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0rem;
    font-family: Montserrat;
}