@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&amp;display=swap");

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

body {
  /* overflow-y: hidden; */
}

.body-height {
  /* height: 500px;
  overflow-x: hidden;
  overflow-y: auto; */
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

.hide-in-desktop {
  display: none;
}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }

}

.slick-slide img {
  height: 80vh;
}

input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 16px;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #fd3d6b;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #fd3d6b;
}


/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #f50;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #fd3d6b;
}

.custom-range {
  height: 10px !important;
  border-radius: 8px;
}



/* input[type='range'] {
  overflow: hidden;
  width: 100%;
  -webkit-appearance: none;
  background-color: #ddd;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  color: #fd3d6b;
  margin-top: -1px;
}

input[type='range']::-webkit-slider-thumb {
  width: 9px;
  height: 9px;
  cursor: ew-resize;
  background: #000;
  box-shadow: -60px 0 0 60px #fd3d6b;
}

.custom-range {
  height: 10px !important;
  border-radius: 8px;
} */




@media only screen and (max-width: 992px) {
  .hide-in-mobile {
    display: none;
  }

  .sticky-nav {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #FFF;
    z-index: 100;
  }

  .hide-in-desktop {
    display: block;
  }

  .mobile-navbar {
    left: 0;
    top:10;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-sign-in {
    color: #fd3f6b;
    text-decoration: none !important;
    font-size: 16px;
    font-weight: 600;
  }

  .header-padding {
    padding: 5px 7px !important;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #FFF;
    z-index: 100;
    margin-bottom: 50px;
  }


  .slick-slide img {
    object-fit: fill;
    height: 250px;
  }

  .header-padding a {
    color: #fb5d5d;
    text-decoration: none !important;
    font-size: 19px;
    font-weight: 600;
  }
}

.product-card-pd {
  padding: 10px;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}

.row {
  --bs-gutter-x: 30px;
}

/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
/*-- Common Style --*/
*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.625;
  position: relative;
  visibility: visible;
  color: #474747;
}

body[dir=rtl] {
  text-align: right;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito Sans", sans-serif;
  color: #000;
}

a,
button {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  background-color: transparent;
  border: 0;
}

textarea {
  width: 100%;
  padding: 5px;
  outline: 0;
}

label {
  margin-bottom: 10px;
}

.form-control input {
  background-color: var(--clr-grey-10) !important;
  min-height: 2vh;
}

.form-control {

  display: block;
  width: 100%;
  min-height: 45px;
  padding: 6px 80px 6px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.625;
  color: #474747;
  border-radius: 5px;
  background-color: #fff;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ced4da;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}



p:last-child {
  margin-bottom: 30px;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  margin: 0;
  list-style: none;
}

li {
  list-style: none;
}

input:focus,
button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input {
  display: inline-block;
  line-height: 50px;
  height: 35px;
  font-size: 15px;
  width: 100%;
  background: transparent;
  border-radius: 0;
  -webkit-border-radius: 0;
  text-align: left;
  box-shadow: none;
  -webkit-box-shadow: none;
  padding-left: 20px;
  padding-right: 10px;
  outline: 0;
}

.img-responsive {
  max-width: 100%;
  display: block;
  width: 100%;
}

.pt-100px {
  padding-top: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-100px {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-100px {
    padding-top: 60px;
  }
}

.forget-link input {
  height: 10px !important;
  width: 10px !important;
  padding: 10px !important;
}

.forget-link a {
  float: right;
  top: -60px;
  position: relative;
  color: #fd3f6b;
}

@media only screen and (max-width: 767px) {
  .pt-100px {
    padding-top: 60px;
  }

  .new {
    padding: 0px 0px 0px 0px;
  }

  .forget-link a {
    float: right;
    top: -50px;
    position: relative;
  }

  .forget-link input {
    height: 10px !important;
    width: 10px !important;
    padding: 10px !important;
  }
}

.pb-100px {
  padding-bottom: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-100px {
    padding-bottom: 80px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-100px {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-100px {
    padding-bottom: 60px;
  }
}

.pt-70px {
  padding-top: 70px;
}

.pt-30px {
  padding-top: 30px;
}

.pb-40px {
  padding-bottom: 40px;
}

.line-height-1 {
  line-height: 1;
}

.mb-30px0px {
  margin-bottom: 60px;
}

.mt-30px0px {
  margin-top: 60px;
}

.mt-30px {
  margin-top: 30px;
}

.mb-30px {
  margin-bottom: 30px;
}

.ml-20px {
  margin-left: 20px;
}

.ml-15px {
  margin-left: 15px;
}

.mr-15px {
  margin-right: 15px;
}

.ml-5px {
  margin-left: 5px;
}

.ml-10px {
  margin-left: 10px;
}

.pl-35px {
  padding-left: 35px;
}

.pr-35px {
  padding-right: 35px;
}

.mb-n-50 {
  margin-bottom: -50px;
}

.mb-50px {
  margin-bottom: 50px;
}

.mb-40px {
  margin-bottom: 40px;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.bg-black {
  background-color: #2f333a;
}

.bg-gray {
  background-color: #f4f4f4;
}

#scrollUp {
  width: 50px;
  height: 50px;
  background-color: #fb5d5d;
  color: #fff;
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
  font-size: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#scrollUp:hover {
  background: #fb5d5d;
  color: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.slider-animated-1>* {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.swiper-slide-active .slider-animated-1>* {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.swiper-slide-active .slider-animated-1>*:nth-child(1) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.swiper-slide-active .slider-animated-1>*:nth-child(2) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.swiper-slide-active .slider-animated-1>*:nth-child(3) {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.swiper-slide-active .slider-animated-1>*:nth-child(4) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.swiper-slide-active .slider-animated-1>*:nth-child(5) {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.swiper-slide-active .slider-animated-1>*:nth-child(6) {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.section-title .title {
  font-size: 36px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  margin-bottom: 11px;
  background: linear-gradient(-96deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .section-title .title {
    font-size: 30px;
  }
}

/* Fixed Header */
.sticky-nav.menu_fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-animation: sticky 1s;
  animation: sticky 1s;
  -webkit-box-shadow: 2px 4px 8px rgba(51, 51, 51, 0.25);
  box-shadow: 2px 4px 8px rgba(51, 51, 51, 0.25);
  background-color: #fff;
}

@-webkit-keyframes sticky {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes sticky {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

.header-to-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #b5b5b5;
  font-size: 14px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  line-height: 50px;
  background-color: #242424;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 300;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .header-main {
    padding: 15px 0;
  }


}

/* Actions */
.header-actions a {
  color: #fb5d5d;
  text-decoration: none !important;
}

.header-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header-actions>*+* {
  margin-left: 20px;
}

@media only screen and (max-width: 575px) {
  .header-actions>*+* {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .header-actions>*+* {
    margin-left: 10px;
  }
}

.header-actions .header-action-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  color: #000000;
  font-weight: 600;
}

.header-actions .header-action-btn.login-btn {
  color: #fb5d5d;
}

@media only screen and (max-width: 479px) {
  .header-actions .header-action-btn.login-btn {
    font-size: 13px;
  }
}

.header-actions .header-action-btn:hover {
  color: #fb5d5d;
}

.header-actions .header-action-btn i {
  -webkit-transition: 0s;
  transition: 0s;
  font-size: 25px;
}

@media only screen and (max-width: 575px) {
  .header-actions .header-action-btn i {
    font-size: 25px;
  }
}

@media only screen and (max-width: 479px) {
  .header-actions .header-action-btn i {
    font-size: 22px;
  }
}

.header-actions .header-action-btn i:hover {
  color: #fb5d5d;
}

.header-actions .header-action-btn .header-action-num {
  font-size: 11px;
  font-weight: 700;
  position: absolute;
  z-index: 2;
  top: -10px;
  right: -7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background-color: #fb5d5d;
  width: 21px;
  height: 21px;
}

.header-actions .header-action-btn .cart-amount {
  display: block;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding-left: 15px;
  color: #0f0f0f;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-actions .header-action-btn:hover .cart-amount {
  color: #fb5d5d;
}

/* search category */
/* Main Menu */
.main-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.main-menu ul li+li {
  margin-left: 50px;
}

.main-menu ul li a {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #4c4c4c;
  display: block;
  position: relative;
  line-height: 60px;
}

.main-menu ul li a i {
  font-size: 24px;
  position: absolute;
  display: inline-block;
  top: 50%;
  margin-top: -14px;
}

.main-menu ul li:hover>a,
.main-menu ul li.active>a {
  color: #fb5d5d !important;
}

.main-menu ul li.dropdown {
  position: relative;
}

.main-menu ul li.dropdown ul.sub-menu {
  position: absolute;
  z-index: 9;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  min-width: 205px;
  left: auto !important;
  background: #fff;
  -webkit-box-shadow: 0 0 3.76px 0.24px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 3.76px 0.24px rgba(0, 0, 0, 0.15);
  display: block;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
}

.main-menu ul li.dropdown ul li {
  padding: 0;
  margin: 0;
  display: block;
}

.main-menu ul li.dropdown ul li a {
  display: block;
  line-height: 19px;
  padding: 12px 0px 12px 20px;
  border-bottom: 1px solid #eee;
  font-weight: 400;
  font-size: 14px;
  height: 44px;
  color: #000000;
}

.main-menu ul li.dropdown ul li a:hover {
  padding-left: 25px;
}

.main-menu ul li.dropdown:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
}

.main-menu ul li.dropdown:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
}

.main-menu ul li .mega-menu {
  position: absolute;
  -webkit-box-shadow: 0 0 3.76px 0.24px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 3.76px 0.24px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 20px 0px 30px 30px;
  opacity: 0;
  visibility: hidden;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  left: 0;
  width: 100%;
  z-index: 2;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  z-index: 15;
}

.main-menu ul li .mega-menu li ul {
  width: 25%;
  margin-right: 30px;
}

.main-menu ul li .mega-menu li ul li.title a {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  display: block;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 15px;
  padding-bottom: 5px;
  height: auto;
}

.main-menu ul li .mega-menu li ul li.title a:hover {
  color: #fb5d5d;
  padding-left: 0px;
}

.main-menu ul li .mega-menu li ul li a {
  color: #1d1d1d;
  text-transform: capitalize;
  line-height: 30px;
  font-weight: 400;
  font-size: 14px;
  display: block;
  padding: 0;
  border: 0;
  height: auto;
}

.main-menu ul li .mega-menu li ul li a:hover {
  color: #fb5d5d;
  padding-left: 10px;
}

.main-menu ul li .mega-menu ul.menu-banner {
  margin-top: 20px;
}

.main-menu ul li .mega-menu ul.menu-banner li {
  width: 33.33%;
  padding-right: 30px;
}

.main-menu ul li .mega-menu ul.menu-banner li a {
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  height: auto;
  border-radius: 5px;
}

.main-menu ul li .mega-menu ul.menu-banner li a img {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.main-menu ul li .mega-menu ul.menu-banner li a:hover img {
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transform: scale(1.035) rotate(0.05deg);
  transform: scale(1.035) rotate(0.05deg);
}

.main-menu.manu-color-white ul li a {
  color: #000;
}

/* mobile search */

.action-form {
  position: relative;
}

.submit {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  background: #fb5d5d;
  color: #FFF;
  font-size: 20px;
  border-radius: 0px 5px 5px 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

body.offcanvas-open {
  overflow: hidden;
  padding-right: 17px;
}

.offcanvas {
  font-size: 14px;
  font-weight: 400;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: auto;
  display: block;
  width: 400px;
  height: 100%;
  padding: 20px;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: auto;
  /* visibility: visible; */
}

@media only screen and (max-width: 479px) {
  .offcanvas {
    width: 300px;
  }
}

.offcanvas .inner {
  position: relative;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.offcanvas .inner .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 30px 20px;
}

.offcanvas .inner .head .title {
  color: #000;
  font-weight: 700;
}

.offcanvas .inner .head .offcanvas-close {
  position: relative;
  width: 20px;
  height: 20px;
  text-indent: -9999px;
  border: 0;
  background-color: transparent;
}

.offcanvas .inner .head .offcanvas-close::after {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 20px;
  height: 2px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #fb5d5d;
}

.offcanvas .inner .head .offcanvas-close::before {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 20px;
  height: 2px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #fb5d5d;
}

.offcanvas .inner .head .offcanvas-close:hover:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.offcanvas .inner .head .offcanvas-close:hover:after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.offcanvas .inner .offcanvas-userpanel ul li {
  display: block;
  background: transparent;
  width: 100%;
  text-align: left;
  display: block;
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
}

.offcanvas .inner .offcanvas-userpanel ul li.offcanvas-userpanel__role a {
  color: #000;
  display: block;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 600;
}

.offcanvas .inner .offcanvas-userpanel ul li.offcanvas-userpanel__role a i {
  float: right;
  font-size: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.offcanvas .inner .offcanvas-userpanel ul li.offcanvas-userpanel__role a:hover {
  color: #fb5d5d;
}

.offcanvas .inner .offcanvas-userpanel ul li.offcanvas-userpanel__role ul.user-sub-menu {
  display: none;
}

.offcanvas .inner .offcanvas-userpanel ul li.offcanvas-userpanel__role ul.user-sub-menu li a {
  display: block;
  padding: 7px 10px 7px 10px;
  font-size: 12px;
  font-weight: 500;
  color: #000;
}

.offcanvas .inner .offcanvas-userpanel ul li.offcanvas-userpanel__role ul.user-sub-menu li a:hover {
  color: #fb5d5d;
}

.offcanvas .inner .offcanvas-userpanel ul li.offcanvas-userpanel__role ul.user-sub-menu li a.current {
  color: #fb5d5d;
}

.offcanvas .inner .offcanvas-menu ul li {
  position: relative;
  display: block;
  line-height: 28px;
}

.offcanvas .inner .offcanvas-menu ul li a {
  display: block;
  padding: 10px 0px;
  text-transform: capitalize;
  color: #474747;
  border-top: 1px solid #eee;
  font-size: 16px;
  font-weight: 600;
}

.offcanvas .inner .offcanvas-menu ul li .sub-menu {
  position: static;
  top: auto;
  display: none;
  visibility: visible;
  width: 100%;
  min-width: auto;
  padding: 0;
  -webkit-transition: none;
  transition: none;
  opacity: 1;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.offcanvas .inner .offcanvas-menu ul li .sub-menu li {
  line-height: inherit;
  position: relative;
}

.offcanvas .inner .offcanvas-menu ul li .sub-menu li a {
  text-transform: capitalize;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 0px;
  display: block;
  border-top: 1px solid #eee;
  font-size: 14px;
  font-weight: 500;
}

.offcanvas .inner .offcanvas-menu ul li .sub-menu li:last-child {
  border-bottom: 0px solid #eee;
}

.offcanvas .inner .offcanvas-menu ul li .menu-expand {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0px;
  width: 24px;
  height: 49px;
  cursor: pointer;
  background-color: transparent;
}

.offcanvas .inner .offcanvas-menu ul li:hover>a {
  color: #fb5d5d;
}

.offcanvas .inner .offcanvas-menu ul li:hover>span.menu-expand:before {
  background: #fb5d5d;
}

.offcanvas .inner .offcanvas-menu ul li:hover>span.menu-expand:after {
  background: #fb5d5d;
}

.offcanvas .inner .offcanvas-menu ul li:last-child {
  border-bottom: 1px solid #eee;
}

.offcanvas .inner .offcanvas-social li {
  display: inline-block;
  margin: 0 10px 0px 0;
  line-height: 40px;
  padding: 0;
}

@media only screen and (max-width: 479px) {
  .offcanvas .inner .offcanvas-social li {
    margin: 0 5px 0px 0;
  }
}

.offcanvas .inner .offcanvas-social li a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  background: #fb5d5d;
  font-size: 16px;
  padding: 0;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  border: none;
  z-index: 1;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.offcanvas .inner .offcanvas-social li a:hover {
  background: #000;
  color: #fff;
}

@media only screen and (max-width: 479px) {
  .offcanvas .inner .offcanvas-social li a {
    line-height: 35px;
    width: 35px;
    height: 35px;
    font-size: 15px;
  }
}

.offcanvas .inner .foot .buttons a {
  display: block;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  border: none;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 15px;
  line-height: 26px;
  border: none;
  background: #000;
  border-radius: 0px;
  width: auto;
  height: auto;
}

.offcanvas .inner .foot .buttons a:hover {
  background-color: #fb5d5d;
  color: #fff;
}

.offcanvas .inner .foot .buttons .current-btn {
  background-color: #fb5d5d;
  color: #fff;
}

.offcanvas .inner .foot .sub-total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #eee;
  margin: 30px 0 0 0px;
}

.offcanvas .inner .foot .sub-total .table> :not(caption)>*>* {
  padding: 0;
  border: 0;
}

.offcanvas .inner .foot .sub-total .table>tbody>tr>td {
  border: none;
  padding: 0;
  color: #000;
  text-transform: capitalize;
  font-size: 20px;
  vertical-align: middle;
  font-weight: 500;
  line-height: 30px;
}

.offcanvas .inner .foot .sub-total .table>tbody>tr>td.theme-color {
  color: #fb5d5d;
}

.offcanvas .inner .foot .sub-total .amount {
  color: #eb2606;
  font-weight: 600;
}

.offcanvas .inner .foot .minicart-message {
  margin-top: 30px;
  margin-bottom: 30px;
}

.offcanvas-menu>ul>li .menu-expand {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 24px;
  height: 44px;
  cursor: pointer;
  background-color: transparent;
}

.offcanvas-menu>ul.sub-menu>li .menu-expand {
  right: 15px;
}

.offcanvas.offcanvas-mobile-menu {
  right: auto;
  left: 0;
  padding: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  width: 350px;
  padding: 50px 30px;
}

@media only screen and (max-width: 479px) {
  .offcanvas.offcanvas-mobile-menu {
    width: 300px;
  }
}

.offcanvas.offcanvas-mobile-menu .offcanvas-close {
  position: absolute;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
  border: 0;
  left: 0%;
  top: 5px;
  background-color: #fb5d5d;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  border-radius: 50%;
}

.offcanvas.offcanvas-mobile-menu .offcanvas-close::after {
  position: absolute;
  top: calc(50% - 1px);
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 2px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #fff;
}

.offcanvas.offcanvas-mobile-menu .offcanvas-close::before {
  position: absolute;
  top: calc(50% - 1px);
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 2px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #fff;
}

.offcanvas.offcanvas-mobile-menu .offcanvas-close:hover:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.offcanvas.offcanvas-mobile-menu .offcanvas-close:hover:after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.offcanvas.offcanvas-cart .inner .head,
.offcanvas.offcanvas-wishlist .inner .head {
  margin-bottom: 30px;
  padding: 0;
}

.offcanvas.offcanvas-cart .inner .head .title,
.offcanvas.offcanvas-wishlist .inner .head .title {
  font-size: 20px;
}

.offcanvas-menu>ul>li .menu-expand::before,
.offcanvas-menu>ul>li .menu-expand::after {
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 7px);
  width: 14px;
  height: 2px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  background-color: #000;
}

.offcanvas-menu>ul>li .menu-expand::after {
  -webkit-transform: rotate(90deg) scale(0.75);
  transform: rotate(90deg) scale(0.75);
}

.offcanvas-menu>ul>li.active>.menu-expand::after {
  -webkit-transform: rotate(0) scale(0.75);
  transform: rotate(0) scale(0.75);
}

.offcanvas-menu>ul>li .sub-menu li.active>.menu-expand::after {
  -webkit-transform: rotate(0) scale(0.75);
  transform: rotate(0) scale(0.75);
}

.offcanvas.offcanvas-mobile-menu.offcanvas-open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.offcanvas.offcanvas-mobile-menu.offcanvas-open .offcanvas-close {
  left: 80%;
}

.offcanvas-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.offcanvas.offcanvas-open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.minicart-product-list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.minicart-product-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ebebeb;
}

.minicart-product-list li .image {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 75px;
  flex: 1 0 75px;
}

.minicart-product-list li .image img {
  max-width: 100%;
  border: 1px solid #ebebeb;
}

@media only screen and (max-width: 575px) {
  .minicart-product-list li .image {
    -ms-flex: 1 0 50px;
    -webkit-box-flex: 1;
    flex: 1 0 50px;
  }
}

.minicart-product-list li .content {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 calc(100% - 150px);
  flex: 1 0 calc(100% - 150px);
  padding-left: 15px;
}

.minicart-product-list li .content .title {
  color: #000;
  font-weight: 400;
}

.minicart-product-list li .content .title:hover {
  color: #fb5d5d;
}

.minicart-product-list li .content .quantity-price {
  font-size: 14px;
  display: block;
  margin-top: 10px;
}

.minicart-product-list li .content .quantity-price .amount {
  color: #fb5d5d;
  font-weight: 500;
  font-size: 18px;
}

.minicart-product-list li .content .remove {
  line-height: 1.5;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 3px;
  color: #000;
  font-size: 16px;
}

.minicart-product-list li .content .remove:hover {
  color: #eb2606;
}

@media only screen and (max-width: 575px) {
  .minicart-product-list li .content {
    -ms-flex: 1 0 calc(100% - 75px);
    -webkit-box-flex: 1;
    flex: 1 0 calc(100% - 75px);
  }
}

.minicart-product-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.bg-color1 {
  background-color: #fcf6f6;
}

.bg-color2 {
  background-color: #cebdb5;
}

.hero-slide-item-2 {
  border-left: 30px solid #fff;
  border-right: 30px solid #fff;
}

@media only screen and (max-width: 479px) {
  .hero-slide-item-2 {
    border: 0;
  }
}

.slider-height {
  height: 800px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slider-height {
    height: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height {
    height: 450px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-height {
    height: 750px;
  }
}

.slider-height-2 {
  height: 800px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slider-height-2 {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-2 {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-2 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-2 {
    height: 450px;
  }
}

.hero-slide-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.hero-slide-image img {
  max-width: 100%;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slide-image img {
    width: 80%;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slide-image img {
    width: 60%;
    margin: auto;
  }
}

@media only screen and (max-width: 479px) {
  .hero-slide-image img {
    width: 70%;
    margin: auto;
  }
}

@media only screen and (max-width: 575px) {
  .hero-slide-image {
    margin-top: 50px;
    text-align: center;
  }
}

@media only screen and (max-width: 575px) {
  .sm-center-view {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.swiper-slide-active .hero-slide-image img {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.hero-slide-content {
  position: relative;
  z-index: 9;
  text-transform: uppercase;
}

.hero-slide-content .category {
  font-size: 18px;
  font-weight: 600;
  display: block;
  position: relative;
  padding-left: 50px;
  color: #3c3c3c;
  margin-bottom: 15px;
}

.hero-slide-content .category::before {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1.5px;
  background-color: #fb5d5d;
  width: 30px;
  height: 3px;
  content: "";
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slide-content .category {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slide-content .category {
    font-size: 18px;
  }
}

.hero-slide-content .title-1 {
  font-size: 72px;
  font-weight: 300;
  margin-bottom: 10px;
  color: #3c3c3c;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slide-content .title-1 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slide-content .title-1 {
    font-size: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slide-content .title-1 {
    font-size: 30px;
  }
}

.hero-slide-content .title-2 {
  font-size: 72px;
  font-weight: 700;
  margin-bottom: 47px;
  color: #3c3c3c;
}

.hero-slide-content .title-2 span {
  font-weight: 800;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slide-content .title-2 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slide-content .title-2 {
    font-size: 42px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slide-content .title-2 {
    font-size: 30px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .hero-slide-content .btn {
    width: 150px;
    height: 50px;
    font-size: 14px;
  }
}

.hero-slide-content .btn i {
  font-size: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .hero-slide-content .btn i {
    font-size: 14px;
  }
}

.hero-slide-content.hero-slide-content-2 .title-1 {
  text-transform: capitalize;
  font-weight: 800;
  margin-bottom: 37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slide-content.hero-slide-content-2 .title-1 {
    font-size: 54px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slide-content.hero-slide-content-2 .title-1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slide-content.hero-slide-content-2 .title-1 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .show-case {
    position: relative;
  }
}

.swiper-slide-active .display-wrapper {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.display-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 252px;
  height: 136px;
  -webkit-box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.22);
  box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px;
  position: absolute;
  bottom: 106px;
  right: 0;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fff;
  z-index: 1;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .display-wrapper {
    bottom: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .display-wrapper {
    bottom: 0px;
  }
}

.display-wrapper .content-side .title {
  font-size: 21px;
  font-weight: 600;
  color: #393939;
}

.display-wrapper .content-side .price {
  font-size: 18px;
  color: #2f2f2f;
  font-weight: 700;
  display: block;
}

.display-wrapper .content-side .shop-link {
  font-size: 15px;
  color: #fb5d5d;
  display: inline-block;
  text-decoration: underline;
  margin-top: auto;
}

.m-lrb-30px {
  margin: 0;
  border-top: 0;
}

@media only screen and (max-width: 479px) {
  .m-lrb-30px {
    border: 0;
  }
}

.footer-area {
  color: #fff;
  background: #333333;
}

.footer-area .footer-top {
  padding: 40px 0 40px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-area .footer-top {
    padding: 80px 0 60px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area .footer-top {
    padding: 60px 0 40px 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer-area .footer-top {
    padding: 50px 0 50px 0;
  }
}

.footer-area .footer-top .single-wedge .footer-logo {
  margin: 0 0 27px;
}

.footer-area .footer-top .single-wedge .footer-herading {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 27px;
  position: relative;
  color: #fff;
}

.footer-area .footer-top .single-wedge .about-text {
  font-size: 14px;
  max-width: 290px;
  line-height: 22px;
  color: #afafaf;
  font-weight: 300;
}

@media only screen and (max-width: 767px) {
  .footer-area .footer-top .single-wedge .about-text {
    max-width: 454px;
  }
}

.footer-area .footer-top .single-wedge .footer-links li {
  display: block;
  margin: 0;
  line-height: 1;
  border: 0;
  font-weight: 400;
  margin-bottom: 5px;
  position: relative;
}

/* .footer-area .footer-top .single-wedge .footer-links li::after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 6px;
  height: 1px;
  background-color: #afafaf;
  content: "";
  margin-top: -0.5px;
} */

.footer-area .footer-top .single-wedge .footer-links .single-link {
  font-size: 16px;
  line-height: 27px;
  padding: 0;
  display: inline-block;
  text-transform: capitalize;
  color: #afafaf;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  text-decoration: none;
}

.footer-area .footer-top .single-wedge .footer-links .single-link:hover {
  padding-left: 8px;
  color: #fb5d5d;
}

.footer-area .footer-top .single-wedge .footer-links p {
  margin: 0 0 5px;
  font-weight: 300;
  color: #afafaf;
}

.footer-area .footer-top .single-wedge .footer-links p a {
  color: #afafaf;
}

.footer-area .footer-top .single-wedge .footer-links p a:hover {
  color: #fb5d5d;
}

.footer-area .footer-top .single-wedge .footer-links .payment-img {
  margin: 30px 0 0;
}

.footer-area .footer-top .pl-lg-50px {
  padding-left: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-area .footer-top .pl-lg-50px {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area .footer-top .pl-lg-50px {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-area .footer-top .pl-lg-50px {
    padding-left: 15px;
  }
}

.link-follow li {
  list-style: none;
  display: inline-block;
}

.link-follow a {
  color: #fffefe;
  margin-left: 7px;
  font-size: 16px;
  line-height: 32px;
  width: 32px;
  height: 32px;
  text-align: center;
  background-color: transparent;
  display: block;
  border-radius: 50%;
}

.link-follow a:hover {
  color: #fffefe;
  background-color: #fb5d5d;
}

.footer-bottom {
  background: #393939 none repeat scroll 0 0;
  padding: 25px 0 0px 0;
}

.footer-bottom .copy-text {
  color: #d8d8d8;
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
  line-height: 26px;
  font-weight: 300;
}

.footer-bottom .copy-text .company-name {
  color: #d8d8d8;
  font-weight: 300;
}

.footer-bottom .copy-text .company-name:hover {
  color: #fb5d5d;
}

.footer-bottom .copy-text i {
  color: #fb5d5d;
  margin: 0 3px;
  font-size: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-md-30px {
    margin-bottom: 30px;
  }

  .mt-md-30px {
    margin-top: 30px;
  }

  .mt-md-50px {
    margin-top: 50px;
  }

  .mb-md-60px {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .mb-lm-30px {
    margin-bottom: 30px;
  }

  .mt-lm-50px {
    margin-top: 50px;
  }

  .mt-lm-30px {
    margin-top: 30px;
  }

  .mt-lm-20px {
    margin-top: 20px;
  }

  .mb-lm-20px {
    margin-bottom: 20px;
  }

  .mb-lm-60px {
    margin-bottom: 60px;
  }

  .mb-lm-30px {
    margin-bottom: 30px;
  }

  .mb-lm-10px {
    margin-bottom: 10px;
  }

  .mb-lm-15px {
    margin-bottom: 15px;
  }

  .mt-lm-15px {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .mb-xs-30px {
    margin-bottom: 30px;
  }

  .mb-xs-15px {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .mb-es-30px {
    margin-bottom: 30px;
  }
}

.modal-dialog {
  margin: 0% auto;
  max-width: 960px;
  width: 960px;
  padding: 35px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog {
    max-width: 720px;
    width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 98%;
    max-width: 100%;
    padding: 35px 0;
    margin: auto 2%;
  }
}

.modal-dialog .gallery-thumbs {
  width: 95%;
  margin: auto;
}

.modal-dialog .gallery-thumbs .swiper-slide {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.modal-dialog .gallery-thumbs .swiper-slide:hover {
  opacity: 0.5;
}

.modal-dialog .gallery-thumbs .swiper-slide.swiper-slide-thumb-active {
  opacity: 0.5;
}

.modal-dialog .social-info ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.modal.fade {
  display: block !important;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.modal.fade.show {
  display: block !important;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.shop-category-area .shop-top-bar {
  margin-bottom: 60px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  padding: 6px 10px 7px 30px;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
}

@media only screen and (max-width: 767px) {
  .shop-category-area .shop-top-bar {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-category-area .shop-top-bar {
    padding: 15px 10px 5px 30px;
  }
}

.shop-category-area .shop-top-bar p {
  margin: 0;
}

.shop-category-area .shop-top-bar p span {
  color: #fb5d5d;
}

@media only screen and (max-width: 767px) {
  .shop-category-area .shop-top-bar.d-flex {
    display: block !important;
  }
}

.shop-tab {
  line-height: 1;
}

.shop-tab a+a {
  margin-left: 20px;
}

.shop-tab a {
  font-size: 18px;
  color: #9e9e9e;
}

.shop-tab a.active {
  color: #fb5d5d;
}

@media only screen and (max-width: 767px) {
  .shop-tab {
    margin-top: 15px;
    margin-bottom: 5px;
  }
}

.shop-select .nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  border-radius: 0px;
  border: solid 1px transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  height: 45px;
  line-height: 45px;
  outline: none;
  padding-left: 10px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  max-width: 200px;
  width: 200px;
  color: #6c6c6c;
}

@media only screen and (max-width: 575px) {
  .shop-select .nice-select {
    max-width: 200px;
  }
}

.shop-select .nice-select .current {
  color: #090909;
}

.shop-select .nice-select:after {
  border: 0;
  content: "";
  display: inline-block;
  font-family: "FontAwesome";
  height: auto;
  margin-top: 0;
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: auto;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: auto;
  color: #fb5d5d;
  font-size: 16px;
}

.shop-select .nice-select .list {
  background-color: #fff;
  border-radius: 0px;
  -webkit-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 8px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translate(-21px);
  transform: scale(0.75) translate(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 15;
  width: 100%;
  color: #090909;
  font-size: 16px;
}

.shop-select .nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translate(0px);
  transform: scale(1) translate(0px);
}

.shop-sidebar-wrap .sidebar-widget-search {
  margin: 0 0 40px;
  position: relative;
}

.shop-sidebar-wrap .sidebar-widget-search #widgets-searchbox .input-field {
  border: 1px solid #cfcfcf;
  background-color: #f9f9f9;
  border-radius: 10px;
  width: 100%;
  height: 60px;
  padding-left: 15px;
  padding-right: 75px;
  font-size: 16px;
  font-style: italic;
  color: #969696;
  background-color: transparent;
}

.shop-sidebar-wrap .sidebar-widget-search #widgets-searchbox .input-field::-webkit-input-placeholder {
  color: #969696;
}

.shop-sidebar-wrap .sidebar-widget-search #widgets-searchbox .input-field::-moz-placeholder {
  color: #969696;
}

.shop-sidebar-wrap .sidebar-widget-search #widgets-searchbox .input-field:-ms-input-placeholder {
  color: #969696;
}

.shop-sidebar-wrap .sidebar-widget-search #widgets-searchbox .input-field::-ms-input-placeholder {
  color: #969696;
}

.shop-sidebar-wrap .sidebar-widget-search #widgets-searchbox .input-field::placeholder {
  color: #969696;
}

.shop-sidebar-wrap .sidebar-widget-search #widgets-searchbox .widgets-searchbox-btn {
  background-color: transparent;
  border: 0;
  border-left: 1px solid #cfcfcf;
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
  color: #cfcfcf;
  line-height: 1;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.shop-sidebar-wrap .sidebar-widget-search #widgets-searchbox .widgets-searchbox-btn:hover {
  color: #fb5d5d;
}

.shop-sidebar-wrap .sidebar-widget-category li a {
  color: #737070;
  font-size: 16px;
  display: inline-block;
  margin-top: 12px;
}

.shop-sidebar-wrap .sidebar-widget-category li a:hover {
  color: #fb5d5d;
}

.shop-sidebar-wrap .sidebar-widget {
  background-color: #fafafa;
  border-radius: 10px;
  padding: 40px 30px 40px 30px;
  line-height: 1;
  margin: 0 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sidebar-wrap .sidebar-widget {
    padding: 30px 20px 30px 20px;
  }
}

.shop-sidebar-wrap .sidebar-widget h4 {
  font-size: 18px;
  display: block;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
  color: #525252;
  margin: 0 0 20px;
  padding-left: 20px;
}

.shop-sidebar-wrap .sidebar-widget h4:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 11px;
  height: 11px;
  background: transparent;
  border-radius: 50%;
  border: 2px solid #fb5d5d;
  margin-top: -5.5px;
}

.shop-sidebar-wrap .sidebar-widget .price-slider-amount input {
  color: #474747;
  font-size: 14px;
  border: 0;
}

.shop-sidebar-wrap .sidebar-widget .ui-widget-content {
  border: 1px solid #fb5d5d;
}

.shop-sidebar-wrap .sidebar-widget .ui-slider-horizontal {
  height: 3px;
  margin: 20px 0 0;
}

.shop-sidebar-wrap .sidebar-widget .ui-widget-header {
  background: #fb5d5d;
}

.shop-sidebar-wrap .sidebar-widget .ui-slider-handle {
  top: 50%;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 100%;
  outline: none;
  margin-top: -8px;
  border: 5px solid #fb5d5d;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.shop-sidebar-wrap .sidebar-widget .ui-slider-handle.ui-state-hover {
  background: #fb5d5d;
}

.shop-sidebar-wrap .sidebar-widget-tag li {
  line-height: 1;
  display: inline-block;
  margin: 8px 1px;
}

.shop-sidebar-wrap .sidebar-widget-tag li a {
  display: block;
  float: left;
  padding: 10px 20px;
  font-size: 16px;
  color: #7e7e7e;
  font-weight: 400;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: transparent;
  text-transform: capitalize;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sidebar-wrap .sidebar-widget-tag li a {
    padding: 8px 14px;
    font-size: 14px;
  }
}

.shop-sidebar-wrap .sidebar-widget-tag li a:hover {
  background-color: #fb5d5d;
  border-color: #fb5d5d;
  color: #fff;
}

.shop-sidebar-wrap .sidebar-widget-list.color ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.shop-sidebar-wrap .sidebar-widget-list.color ul li+li {
  margin-left: 10px;
}

.shop-sidebar-wrap .sidebar-widget-list.color ul li a {
  width: 42px;
  height: 42px;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sidebar-wrap .sidebar-widget-list.color ul li a {
    width: 32px;
    height: 32px;
  }
}

.shop-sidebar-wrap .sidebar-widget-list.color ul li a:hover {
  border-color: #fb5d5d;
}

.shop-sidebar-wrap .sidebar-widget-list.color ul li a.active {
  border-color: #fb5d5d;
}

.shop-sidebar-wrap .sidebar-widget-list.color ul li a::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 36px;
  height: 36px;
  content: "";
  border-radius: 50%;
  margin-top: -18px;
  margin-left: -18px;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sidebar-wrap .sidebar-widget-list.color ul li a::before {
    width: 26px;
    height: 26px;
    margin-top: -13px;
    margin-left: -13px;
  }
}

.shop-sidebar-wrap .sidebar-widget-list.color ul li a.yellow::before {
  background-color: #d28200;
}

.shop-sidebar-wrap .sidebar-widget-list.color ul li a.black::before {
  background-color: #505050;
}

.shop-sidebar-wrap .sidebar-widget-list.color ul li a.red::before {
  background-color: #ff1616;
}

.shop-sidebar-wrap .sidebar-widget-list.color ul li a.pink::before {
  background-color: #ecbbbf;
}

.shop-sidebar-wrap .sidebar-widget-list.size ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.shop-sidebar-wrap .sidebar-widget-list.size ul li+li {
  margin-left: 10px;
}

.shop-sidebar-wrap .sidebar-widget-list.size ul li a {
  width: 42px;
  height: 42px;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  color: #525252;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sidebar-wrap .sidebar-widget-list.size ul li a {
    width: 32px;
    height: 32px;
    font-size: 14px;
  }
}

.shop-sidebar-wrap .sidebar-widget-list.size ul li a:hover {
  border-color: #fb5d5d;
}

.shop-sidebar-wrap .sidebar-widget-list.size ul li a.active-2 {
  border-color: #fb5d5d;
}

.shop-sidebar-wrap .sidebar-widget-list.size ul li a::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 36px;
  height: 36px;
  content: "";
  border-radius: 50%;
  margin-top: -18px;
  margin-left: -18px;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sidebar-wrap .sidebar-widget-list.size ul li a::before {
    width: 26px;
    height: 26px;
    margin-top: -13px;
    margin-left: -13px;
  }
}

.shop-sidebar-wrap .sidebar-widget-list.size ul li a.gray::before {
  background-color: #d6d6d6;
}

.sidebar-widget-image .single-banner {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 10px;
}

.sidebar-widget-image .single-banner img {
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.sidebar-widget-image .single-banner:hover img {
  -webkit-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}

.sidebar-widget-image .single-banner .item-disc {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 60px 45px;
  line-height: 1;
}

.sidebar-widget-image .single-banner .title {
  font-size: 30px;
  color: #fb5d5d;
  font-weight: 800;
  margin: 0 0 19px;
}

.sidebar-widget-image .single-banner .shop-link {
  font-size: 18px;
  color: #161616;
  font-weight: 600;
  position: relative;
  display: inline-block;
}

.sidebar-widget-image .single-banner .shop-link::after {
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #858585;
  content: "";
  left: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.sidebar-widget-image .single-banner .shop-link:hover {
  color: #fb5d5d;
}

.sidebar-widget-image .single-banner .shop-link:hover::after {
  background-color: #fb5d5d;
}

/* Shop list style */
.shop-list-wrapper .content-desc-wrap .content {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrapper .content-desc-wrap .content {
    margin-top: 30px;
  }
}

.shop-list-wrapper .content-desc-wrap .content .ratings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 4px;
}

.shop-list-wrapper .content-desc-wrap .content .ratings .rating-wrap {
  font-size: 14px;
  line-height: 1;
  position: relative;
  color: #e4e4e4;
  white-space: nowrap;
}

.shop-list-wrapper .content-desc-wrap .content .ratings .rating-wrap::before {
  font-family: "FontAwesome";
  content: "    ";
}

.shop-list-wrapper .content-desc-wrap .content .ratings .rating-wrap .star {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  color: #ffde00;
}

.shop-list-wrapper .content-desc-wrap .content .ratings .rating-wrap .star::before {
  font-family: "FontAwesome";
  content: "    ";
}

.shop-list-wrapper .content-desc-wrap .content .ratings .rating-num {
  font-size: 14px;
  line-height: 1;
  margin-left: 6px;
  color: #9f9e9e;
}

.shop-list-wrapper .content-desc-wrap .content .title {
  font-size: 18px;
  margin: 10px 0 10px;
}

.shop-list-wrapper .content-desc-wrap .content .title a {
  text-decoration: none;
  color: #1d1d1d;
}

.shop-list-wrapper .content-desc-wrap .content .title a:hover {
  color: #fb5d5d;
}

.shop-list-wrapper .content-desc-wrap .content p {
  font-size: 14px;
  color: #6c6c6c;
  line-height: 24px;
}

.shop-list-wrapper .content-desc-wrap .box-inner {
  margin-top: 20px;
}

.shop-list-wrapper .content-desc-wrap .box-inner .price {
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  line-height: 1;
  display: block;
  margin: 0 0 20px;
}

.shop-list-wrapper .content-desc-wrap .box-inner .price .new {
  color: #1d1d1d;
  font-weight: 600;
}

.shop-list-wrapper .content-desc-wrap .box-inner .price .old {
  font-size: 14px;
  margin-left: 10px;
  text-decoration: line-through;
  color: #9f9e9e;
}

.shop-list-wrapper .content-desc-wrap .box-inner .add-to-cart {
  width: 170px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  border: 0;
  border-radius: 30px;
  font-size: 14px;
  z-index: 11;
  font-weight: 600;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
  text-transform: uppercase;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}

.shop-list-wrapper .content-desc-wrap .box-inner .add-to-cart::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  z-index: -1;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.shop-list-wrapper .content-desc-wrap .box-inner .add-to-cart:hover {
  color: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.shop-list-wrapper .content-desc-wrap .box-inner .add-to-cart:hover::before {
  opacity: 1;
}

.shop-list-wrapper .content-desc-wrap .box-inner .actions {
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.shop-list-wrapper .content-desc-wrap .box-inner .actions .action+.action {
  margin-left: 10px;
}

.shop-list-wrapper .content-desc-wrap .box-inner .actions .action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  color: #000000;
  border-radius: 50%;
  background-color: #d6d6d6;
  font-size: 24px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.shop-list-wrapper .content-desc-wrap .box-inner .actions .action::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  z-index: -1;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.shop-list-wrapper .content-desc-wrap .box-inner .actions .action:hover {
  color: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.shop-list-wrapper .content-desc-wrap .box-inner .actions .action:hover::before {
  opacity: 1;
}

.shop-list-wrapper+.shop-list-wrapper {
  margin-top: 50px;
}

.zoom-thumbs {
  width: 95%;
  margin: auto;
}

.zoom-thumbs .swiper-slide img {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.zoom-thumbs .swiper-slide:hover img {
  opacity: 0.5;
}

.zoom-thumbs .swiper-slide.swiper-slide-thumb-active img {
  opacity: 0.5;
}

.product-details-content h2 {
  color: #000000;
  font-size: 30px;
  text-transform: capitalize;
  line-height: 1;
  font-weight: 600;
  margin: 0 0 18px 0;
}

@media only screen and (max-width: 767px) {
  .product-details-content h2 {
    font-size: 28px;
  }
}

.product-details-content .pricing-meta {
  font-size: 24px;
  color: #ee3231;
  line-height: 30px;
  margin-bottom: 20px;
}

.product-details-content .pro-details-rating-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
}

.product-details-content .pro-details-rating-wrap .rating-product {
  margin-right: 5px;
}

.product-details-content .pro-details-rating-wrap .rating-product i {
  color: #fdd835;
  font-size: 16px;
}

.product-details-content .pro-details-rating-wrap .rating-product i+i {
  margin-left: 5px;
}

.product-details-content .pro-details-rating-wrap .read-review a {
  text-decoration: none;
  color: #9f9e9e;
  position: relative;
  margin-left: 8px;
  padding: 0;
  font-size: 16px;
  display: inline-block;
}

.product-details-content .pro-details-rating-wrap .read-review a:hover {
  color: #fb5d5d;
}

.product-details-content .pro-details-list {
  padding: 0 0 30px 0;
  margin: 0 0 15px 0;
  border-bottom: 1px solid #ebebeb;
  display: inline-block;
  width: 100%;
  color: #474747;
}

.product-details-content .pro-details-list p {
  font-weight: 400;
  color: #474747;
  line-height: 24px;
  margin: 0;
}

.product-details-content .pro-details-list li {
  display: block;
  width: 100%;
  line-height: 24px;
}

.product-details-content .pro-details-color-info {
  margin: 35px 0 15px;
}

.product-details-content .pro-details-color-info span {
  color: #525252;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  min-width: 70px;
}

.product-details-content .pro-details-color-info .pro-details-color ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product-details-content .pro-details-color-info .pro-details-color ul li+li {
  margin-left: 10px;
}

.product-details-content .pro-details-color-info .pro-details-color ul li a {
  width: 42px;
  height: 42px;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.product-details-content .pro-details-color-info .pro-details-color ul li a:hover {
  border-color: #fb5d5d;
}

.product-details-content .pro-details-color-info .pro-details-color ul li a.active-color {
  border-color: #fb5d5d;
}

.product-details-content .pro-details-color-info .pro-details-color ul li a::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 36px;
  height: 36px;
  content: "";
  border-radius: 50%;
  margin-top: -18px;
  margin-left: -18px;
  z-index: -1;
}

.product-details-content .pro-details-color-info .pro-details-color ul li a.yellow::before {
  background-color: #d28200;
}

.product-details-content .pro-details-color-info .pro-details-color ul li a.black::before {
  background-color: #505050;
}

.product-details-content .pro-details-color-info .pro-details-color ul li a.red::before {
  background-color: #ff1616;
}

.product-details-content .pro-details-color-info .pro-details-color ul li a.pink::before {
  background-color: #ecbbbf;
}

.product-details-content .pro-details-size-info {
  margin: 30px 0 30px;
}

.product-details-content .pro-details-size-info span {
  color: #525252;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  min-width: 70px;
}

.product-details-content .pro-details-size-info .pro-details-size ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product-details-content .pro-details-size-info .pro-details-size ul li+li {
  margin-left: 10px;
}

.product-details-content .pro-details-size-info .pro-details-size ul li a {
  width: 42px;
  height: 42px;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  color: #525252;
}

.product-details-content .pro-details-size-info .pro-details-size ul li a:hover {
  border-color: #fb5d5d;
}

.product-details-content .pro-details-size-info .pro-details-size ul li a.active-size {
  border-color: #fb5d5d;
}

.product-details-content .pro-details-size-info .pro-details-size ul li a::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 36px;
  height: 36px;
  content: "";
  border-radius: 50%;
  margin-top: -18px;
  margin-left: -18px;
  z-index: -1;
}

.product-details-content .pro-details-size-info .pro-details-size ul li a.gray::before {
  background-color: #d6d6d6;
}

.product-details-content p {
  color: #6c788c;
  font-weight: 300;
  line-height: 30px;
}

.product-details-content .pro-details-quality {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 30px 0;
  width: 100%;
}

.product-details-content .pro-details-quality .cart-plus-minus {
  border: 1px solid #ebebeb;
  display: inline-block;
  height: 50px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 80px;
  background: #505050;
  border-radius: 5px;
}

.product-details-content .pro-details-quality .cart-plus-minus .qtybutton {
  color: #fff;
  cursor: pointer;
  float: inherit;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 30px;
}

.product-details-content .pro-details-quality .cart-plus-minus input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  float: left;
  font-size: 14px;
  height: 50px;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 80px;
  outline: none;
}

.product-details-content .pro-details-quality .cart-plus-minus .inc.qtybutton {
  height: 50px;
  padding-top: 14px;
  right: 0;
  top: 0;
}

.product-details-content .pro-details-quality .cart-plus-minus .dec.qtybutton {
  height: 50px;
  left: 0;
  padding-top: 14px;
  top: 0;
}

.product-details-content .pro-details-quality .pro-details-cart .add-cart {
  position: relative;
  padding: 0 35px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-transform: uppercase;
  display: inline-block;
  margin-left: 10px;
  background: #fb5d5d;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 2px;
}

.product-details-content .pro-details-quality .pro-details-cart .add-cart:hover {
  background-color: #000000;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-quality .pro-details-cart .add-cart {
    padding: 0 12px;
    letter-spacing: 0px;
  }
}

.product-details-content .pro-details-compare-wishlist a {
  padding: 0;
  border: none;
  color: #fff;
  font-size: 18px;
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #3d3d3d;
  width: 50px;
  height: 50px;
}

.product-details-content .pro-details-compare-wishlist a:hover {
  background-color: #000000;
}

.product-details-content .pro-details-same-style span {
  font-weight: 600;
  color: #5f5f5f;
  display: inline-block;
  margin-right: 5px;
}

.product-details-content .pro-details-same-style a {
  font-weight: 400;
  color: #9f9e9e;
}

.product-details-content .pro-details-same-style a:hover {
  color: #fb5d5d;
}

.product-details-content .pro-details-same-style.pro-details-categories-info {
  margin: 10px 0;
}

.product-details-content .pro-details-same-style.pro-details-social-info a {
  margin-left: 20px;
}

.description-review-topbar.nav {
  border-bottom: none;
  position: relative;
  margin-bottom: 0;
  margin: auto;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  border-radius: 5px;
  border: 0;
  background-color: #f4f4f4;
}

.description-review-topbar.nav a {
  background: transparent;
  border: 0;
  text-transform: capitalize;
  line-height: 24px;
  color: #3d3d3d;
  padding: 18px 20px;
  margin: 0 10px;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  display: inline-block;
  font-family: "Nunito Sans", sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.description-review-topbar.nav a:hover {
  color: #fff;
  background-color: #fb5d5d;
}

.description-review-topbar.nav a.active {
  color: #fff;
  background-color: #fb5d5d;
}

@media only screen and (max-width: 767px) {
  .description-review-topbar.nav a {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 575px) {
  .description-review-topbar.nav a {
    margin: 0 15px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 479px) {
  .description-review-topbar.nav a {
    margin: 0 5px;
    font-size: 14px;
    padding: 18px 10px;
  }
}

.description-review-bottom {
  overflow: hidden;
  font-size: 16px;
  background: #fff;
  padding: 60px 0px 0px 0px;
}

.description-review-bottom .product-description-wrapper {
  text-align: center;
}

.description-review-bottom .product-description-wrapper p {
  margin: 0px;
  font-size: 16px;
  line-height: 30px;
  color: #6c788c;
  font-weight: 300;
}

.description-review-bottom .product-anotherinfo-wrapper li {
  color: #3d3d3d;
  font-size: 16px;
  list-style: outside none none;
  margin: 0 0 13px;
  display: block;
}

.description-review-bottom .product-anotherinfo-wrapper li span {
  color: #525252;
  display: inline-block;
  font-weight: 500;
  margin: 0 26px 0 0;
  min-width: 85px;
}

.description-review-bottom .single-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 50px;
}

.description-review-bottom .single-review .review-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  margin: 0 15px 0 0;
}

@media only screen and (max-width: 767px) {
  .description-review-bottom .single-review .review-img {
    margin: 0px 0px 20px 0px;
  }
}

.description-review-bottom .single-review .review-top-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.description-review-bottom .single-review .review-top-wrap .review-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.description-review-bottom .single-review .review-top-wrap .review-left .review-name {
  margin-right: 15px;
}

.description-review-bottom .single-review .review-top-wrap .review-left .review-name h4 {
  font-size: 20px;
  margin: 0;
  line-height: 1;
  font-weight: 600;
  color: #525252;
}

.description-review-bottom .single-review .review-top-wrap .review-left .rating-product {
  line-height: 1;
}

.description-review-bottom .single-review .review-top-wrap .review-left .rating-product i {
  overflow: hidden;
  font-size: 14px;
  color: #fdd835;
}

.description-review-bottom .single-review .review-top-wrap .review-left a {
  color: #525252;
}

.description-review-bottom .single-review .review-top-wrap .review-left a:hover {
  color: #fb5d5d;
}

.description-review-bottom .single-review .review-bottom p {
  margin: 0;
  width: 93%;
  color: #6c788c;
}

.description-review-bottom .single-review.child-review {
  margin-left: 70px;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .description-review-bottom .single-review.child-review {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-bottom .single-review {
    display: block;
  }
}

.description-review-bottom .ratting-form-wrapper {
  padding-left: 50px;
  line-height: 1;
}

.description-review-bottom .ratting-form-wrapper h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #525252;
}

.description-review-bottom .ratting-form-wrapper .star-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 0 20px;
}

.description-review-bottom .ratting-form-wrapper .star-box span {
  margin: 0 15px 0 0;
  font-size: 18px;
  color: #525252;
  font-weight: 600;
}

.description-review-bottom .ratting-form-wrapper .star-box i {
  overflow: hidden;
  font-size: 14px;
  color: #fdd835;
}

.description-review-bottom .ratting-form-wrapper .rating-form-style {
  margin-bottom: 10px;
}

.description-review-bottom .ratting-form-wrapper .rating-form-style input {
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  width: 100%;
  margin-bottom: 15px;
  outline: none;
  padding: 2px 10px 2px 20px;
  background: transparent;
  border: 1px solid #ebebeb;
  color: #474747;
  padding: 2px 10px 2px 20px;
}

.description-review-bottom .ratting-form-wrapper .rating-form-style input:focus {
  border-color: #fb5d5d;
}

.description-review-bottom .ratting-form-wrapper .rating-form-style textarea {
  background: transparent;
  border: 1px solid #ebebeb;
  color: #474747;
  height: 180px;
  padding: 20px 10px 2px 20px;
  margin-bottom: 20px;
  width: 100%;
  outline: none;
}

.description-review-bottom .ratting-form-wrapper .rating-form-style textarea:focus {
  border-color: #fb5d5d;
}

.description-review-bottom .ratting-form-wrapper .rating-form-style button {
  width: 170px;
  height: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-bottom .ratting-form-wrapper {
    margin-top: 50px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-bottom .ratting-form-wrapper {
    margin-top: 50px;
    padding: 0;
  }
}

.pro-details-size-color {
  margin: 20px 0 0px;
}

.pro-details-size-color .pro-details-color-wrap {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.pro-details-size-color .pro-details-color-wrap span {
  display: block;
  margin: 0 0 10px;
}

.pro-details-size-color .pro-details-color-wrap .pro-details-color-content li {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  margin-right: 15px;
  -webkit-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  background-size: contain;
  background: #fff;
}

.pro-details-size-color .pro-details-color-wrap .pro-details-color-content li.active {
  background-color: #434a54;
}

.pro-details-size-color .pro-details-color-wrap .pro-details-color-content li:hover {
  border: 2px solid #434A54;
}

.pro-details-size-color .product-size {
  margin-left: 20px;
}

.pro-details-size-color .product-size span {
  display: block;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 7px;
}

.product-details-table.pro-details-quality {
  display: block;
  margin: 20px 0;
}

.product-details-table .table tbody tr td {
  padding: 10px 15px;
  width: 33.33%;
  text-align: center;
  border: 1px solid #ebebeb;
  vertical-align: middle;
  font-size: 16px;
}

.product-details-table .table tbody tr td a {
  color: #6c788c;
}

.product-details-table .table tbody tr td a:hover {
  color: #fb5d5d;
}

.product-details-img.product-details-tab.product-details-tab-2 {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  overflow: hidden;
}

.product-details-img.product-details-tab.product-details-tab-2.product-details-tab-3 {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.product-details-img.product-details-tab.product-details-tab-2 .zoom-thumbs-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  width: 15%;
}

.product-details-img.product-details-tab.product-details-tab-2 .zoom-top-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 85%;
  flex: 0 0 85%;
  width: 85%;
}

.zoom-thumbs-2 {
  display: inline-block;
  float: left;
  text-align: center;
}

.zoom-thumbs-2 .swiper-slide {
  text-align: center;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  outline: 0px none;
}

.zoom-thumbs-2 .swiper-slide+.swiper-slide {
  margin-top: 5px;
}

.zoom-thumbs-2 .swiper-slide img {
  margin: auto;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.zoom-thumbs-2 .swiper-slide.swiper-slide-thumb-active img {
  opacity: 0.5;
}

.zoom-thumbs-2 .swiper-slide:hover img {
  opacity: 0.5;
}

.single-product-gallery .single-product-slider-item {
  width: 50%;
}

.single-product-gallery img {
  border: 1px solid #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin: auto;
  width: 100%;
}

.single-product-gallery img:hover {
  border-color: #fb5d5d;
}

.single-product-sticky .single-product-slider-item {
  width: 100%;
  text-align: center;
}

.single-product-sticky img {
  border: 1px solid #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 100%;
}

.single-product-sticky img:hover {
  border-color: #fb5d5d;
}

.product-details-content.sticky {
  position: sticky;
  top: 110px;
  left: 0;
  width: 100%;
}

.cart-main-area .cart-page-title {
  color: #000000;
  text-align: left;
  font-size: 24px;
  line-height: 16px;
  font-style: normal;
  text-transform: none;
  margin: 0 0 30px;
  font-weight: 600;
}

.cart-main-area .table-content table {
  border: 1px solid #ebebeb;
  width: 100%;
}

.cart-main-area .table-content table thead>tr {
  background-color: #ebebeb;
  border: 1px solid #ebebeb;
}

.cart-main-area .table-content table thead>tr>th {
  border-top: medium none;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  padding: 21px 45px 22px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
}

.cart-main-area .table-content table tbody>tr {
  border-bottom: 1px solid #ebebeb;
}

.cart-main-area .table-content table tbody>tr td {
  color: #474747;
  font-size: 16px;
  padding: 30px 0;
  text-align: center;
}

.cart-main-area .table-content table tbody>tr td.product-thumbnail {
  width: 150px;
}

.cart-main-area .table-content table tbody>tr td.product-name {
  width: 435px;
}

.cart-main-area .table-content table tbody>tr td.product-name a {
  color: #474747;
  font-size: 16px;
  font-weight: 600;
}

.cart-main-area .table-content table tbody>tr td.product-price-cart {
  width: 435px;
}

.cart-main-area .table-content table tbody>tr td.product-quantity {
  width: 435px;
}

.cart-main-area .table-content table tbody>tr td.product-quantity .cart-plus-minus {
  display: inline-block;
  height: 40px;
  padding: 0;
  position: relative;
  width: 110px;
}

.cart-main-area .table-content table tbody>tr td.product-quantity .cart-plus-minus .dec.qtybutton {
  border-right: 1px solid #e5e5e5;
  height: 40px;
  left: 0;
  padding-top: 8px;
  top: 0;
}

.cart-main-area .table-content table tbody>tr td.product-quantity .cart-plus-minus .inc.qtybutton {
  border-left: 1px solid #e5e5e5;
  height: 40px;
  padding-top: 9px;
  right: 0;
  top: 0;
}

.cart-main-area .table-content table tbody>tr td.product-quantity .cart-plus-minus .qtybutton {
  color: #474747;
  cursor: pointer;
  float: inherit;
  font-size: 16px;
  margin: 0;
  position: absolute;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 20px;
  text-align: center;
}

.cart-main-area .table-content table tbody>tr td.product-quantity .cart-plus-minus input.cart-plus-minus-box {
  color: #474747;
  float: left;
  font-size: 16px;
  height: 40px;
  margin: 0;
  width: 110px;
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e1e1e1;
  padding: 0;
  text-align: center;
}

.cart-main-area .table-content table tbody>tr td.product-remove {
  width: 100px;
}

.cart-main-area .table-content table tbody>tr td.product-remove a {
  color: #474747;
  font-size: 16px;
  margin: 0 10px;
}

.cart-main-area .table-content table tbody>tr td.product-remove a:hover {
  color: #fb5d5d;
}

.cart-main-area .table-content table tbody>tr td.product-wishlist-cart>a {
  background-color: #fb5d5d;
  border-radius: 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  padding: 10px 12px;
  text-transform: uppercase;
}

.cart-main-area .table-content table tbody>tr td.product-wishlist-cart>a:hover {
  background-color: #000000;
}

.cart-main-area .cart-shiping-update-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 30px 0 60px;
}

.cart-main-area .cart-shiping-update-wrapper .cart-clear>button {
  border: medium none;
  cursor: pointer;
  margin-right: 27px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .cart-main-area .cart-shiping-update-wrapper {
    display: block;
    padding: 30px 0 15px;
  }
}

.cart-shiping-update-wrapper .cart-shiping-update>a,
.cart-shiping-update-wrapper .cart-clear>button,
.cart-shiping-update-wrapper .cart-clear>a {
  background-color: #f2f2f2;
  border-radius: 0px;
  color: #000000;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 18px 63px 17px;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .cart-shiping-update-wrapper .cart-shiping-update>a,
  .cart-shiping-update-wrapper .cart-clear>button,
  .cart-shiping-update-wrapper .cart-clear>a {
    padding: 18px 25px;
  }
}

@media only screen and (max-width: 767px) {

  .cart-shiping-update-wrapper .cart-shiping-update>a,
  .cart-shiping-update-wrapper .cart-clear>button,
  .cart-shiping-update-wrapper .cart-clear>a {
    padding: 18px 25px;
    margin: 0 0 15px;
  }
}

.cart-shiping-update-wrapper .cart-shiping-update>a:hover,
.cart-shiping-update-wrapper .cart-clear>button:hover {
  background-color: #fb5d5d;
  color: #fff;
}

.cart-shiping-update-wrapper .cart-clear>a {
  background-color: #fb5d5d;
  color: #fff;
}

.cart-shiping-update-wrapper .cart-clear>a:hover {
  background-color: #000000;
  color: #fff;
}

.cart-tax,
.discount-code-wrapper,
.grand-totall {
  background-color: #ebebeb;
  border: 1px solid #ebebeb;
  border-radius: 0px;
  padding: 45px 30px 50px;
}

.cart-tax .title-wrap,
.discount-code-wrapper .title-wrap,
.grand-totall .title-wrap {
  position: relative;
}

.cart-tax .title-wrap::before,
.discount-code-wrapper .title-wrap::before,
.grand-totall .title-wrap::before {
  background-color: #e3e1e1;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 13px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  width: 100%;
  z-index: 1;
}

.cart-tax .title-wrap h4.cart-bottom-title,
.discount-code-wrapper .title-wrap h4.cart-bottom-title,
.grand-totall .title-wrap h4.cart-bottom-title {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding-right: 18px;
  background-color: #ebebeb;
  position: relative;
  z-index: 1;
  color: #000000;
}

.cart-tax .tax-wrapper,
.discount-code-wrapper .tax-wrapper,
.grand-totall .tax-wrapper {
  margin-top: 22px;
}

.tax-select-wrapper {
  margin: 15px 0 0;
}

.tax-select-wrapper select {
  width: 100%;
  height: 42px;
  line-height: 42px;
  border: 1px solid #ebebeb;
  background: #fff;
  padding-left: 20px;
  border-radius: 0px;
}

.tax-select-wrapper input {
  width: 100%;
  height: 42px;
  line-height: 42px;
  border: 1px solid #ebebeb;
  background: #fff;
  padding-left: 20px;
  border-radius: 0px;
}

.tax-select-wrapper .tax-select {
  margin-bottom: 20px;
}

.tax-select-wrapper .tax-select label {
  color: #000000;
  font-size: 16px;
  margin: 0 0 10px;
}

.tax-select-wrapper button.cart-btn-2 {
  background: #fb5d5d;
  border-radius: 0px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 18px 58px;
  text-transform: uppercase;
}

.tax-select-wrapper button.cart-btn-2:hover {
  color: #fff;
  background: #000000;
}

.discount-code-wrapper .title-wrap {
  margin-bottom: 22px;
}

.discount-code-wrapper .discount-code p {
  margin: 0 0 15px;
}

.discount-code-wrapper .discount-code input {
  background: #fff;
  border: 1px solid #ebebeb;
  height: 45px;
  margin-bottom: 30px;
  padding-left: 10px;
  outline: none;
  width: 100%;
  border-radius: 0px;
}

.discount-code-wrapper .discount-code button.cart-btn-2 {
  background: #fb5d5d;
  border-radius: 0px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 18px 58px;
  text-transform: uppercase;
}

.discount-code-wrapper .discount-code button.cart-btn-2:hover {
  color: #fff;
  background: #000000;
}

.grand-totall h5 {
  font-size: 16px;
  margin: 36px 0 27px;
  font-weight: 400;
}

.grand-totall h5 span {
  float: right;
  font-size: 18px;
  font-weight: 700;
}

.grand-totall .total-shipping {
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  margin: 0 0 27px;
  padding: 28px 0;
}

.grand-totall .total-shipping h5 {
  font-size: 16px;
  margin: 0;
}

.grand-totall .total-shipping ul {
  padding: 19px 0 0 0;
}

.grand-totall .total-shipping ul li {
  color: #474747;
  list-style: outside none none;
  margin: 0 0 6px;
  display: block;
}

.grand-totall .total-shipping ul li input {
  background: #e9e9e9 none repeat scroll 0 0;
  border: 1px solid #d7d7d7;
  border-radius: 5px !important;
  color: #474747;
  cursor: pointer;
  height: 13px;
  margin-right: 10px;
  padding: 0;
  position: relative;
  top: 2px;
  width: 13px;
}

.grand-totall .total-shipping ul li span {
  float: right;
}

.grand-totall .grand-totall-title {
  color: #fb5d5d;
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 25px;
}

.grand-totall .grand-totall-title span {
  float: right;
}

.grand-totall a {
  background-color: #fb5d5d;
  border-radius: 0px;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  padding: 18px 10px 17px;
  text-align: center;
  text-transform: uppercase;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.grand-totall a:hover {
  background-color: #000000;
}

.billing-info-wrap h3 {
  font-weight: 600;
  color: #000000;
  margin: 0 0 30px;
  font-size: 24px;
  line-height: 16px;
}

.billing-info-wrap .billing-info input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  color: #474747;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 10px;
  width: 100%;
  outline: none;
  height: 45px;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 10px;
}

.billing-info-wrap .billing-info .checkout-account label {
  color: #474747;
  font-weight: 400;
  margin: 0 0 0 12px;
}

.billing-info-wrap .billing-info .checkout-account input {
  border: 1px solid #ebebeb;
  display: inline-block;
  float: left;
  height: 12px;
  width: 12px;
  margin-top: 7px;
  cursor: pointer;
}

.billing-info-wrap .billing-select select {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  color: #474747;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 10px;
  width: 100%;
  outline: none;
  height: 45px;
}

.billing-info-wrap .checkout-account-toggle input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  color: #474747;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 10px;
  margin: 0 0 20px;
  width: 100%;
  height: 45px;
  outline: none;
}

.billing-info-wrap .checkout-account-toggle button.checkout-btn {
  background-color: #fb5d5d;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  padding: 10px 30px;
  text-transform: uppercase;
  border-radius: 0px;
  z-index: 9;
}

.billing-info-wrap .checkout-account-toggle button.checkout-btn:hover {
  background-color: #000000;
}

.billing-info-wrap .additional-info-wrap {
  margin: 30px 0;
}

.billing-info-wrap .additional-info-wrap h4 {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin: 0 0 10px;
}

.billing-info-wrap .additional-info-wrap textarea {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  color: #474747;
  font-size: 16px;
  height: 138px;
  padding: 17px 20px;
  width: 100%;
  outline: none;
}

.billing-info-wrap label {
  margin-bottom: 10px;
}

.your-order-area h3 {
  font-weight: 600;
  color: #000000;
  margin: 0 0 30px;
  font-size: 24px;
  line-height: 16px;
}

.your-order-area .your-order-wrap {
  padding: 38px 45px 44px;
  background: #ebebeb;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-top ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: "Nunito Sans", sans-serif;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-top ul li {
  font-size: 16px;
  font-weight: 600;
  list-style: outside none none;
  color: #000000;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-middle {
  border-bottom: 1px solid #dee0e4;
  border-top: 1px solid #dee0e4;
  margin: 29px 0;
  padding: 19px 0 18px;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-middle li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 10px;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-bottom ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: "Nunito Sans", sans-serif;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-bottom ul li.your-order-shipping {
  font-size: 16px;
  color: #000000;
  font-weight: 600;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-total {
  border-bottom: 1px solid #dee0e4;
  border-top: 1px solid #dee0e4;
  margin: 18px 0 33px;
  padding: 17px 0 19px;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-total ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-total ul li {
  font-weight: 600;
  color: #fb5d5d;
  font-size: 16px;
  list-style: outside none none;
  font-family: "Nunito Sans", sans-serif;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-total ul li.order-total {
  font-weight: 600;
  color: #000000;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .your-order-area .your-order-wrap {
    padding: 38px 30px 44px;
  }
}

.your-order-area .payment-accordion {
  margin: 0 0 16px;
}

.your-order-area .payment-accordion:last-child {
  margin: 0 0 0;
}

.your-order-area .payment-accordion h4 a {
  color: #000000;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}

.your-order-area .payment-accordion .panel-body {
  padding: 5px 0 0 0;
}

.your-order-area .payment-accordion .panel-body p {
  padding: 0 0 0 10px;
  font-size: 14px;
  color: #474747;
  line-height: 24px;
}

.your-order-area .Place-order {
  margin-top: 25px;
}

.your-order-area .Place-order a {
  background-color: #fb5d5d;
  color: #fff;
  display: block;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1;
  padding: 18px 20px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0px;
  z-index: 9;
}

.your-order-area .Place-order a:hover {
  background-color: #000000;
}

.open-toggle,
.open-toggle2 {
  display: none;
}

.compare-table .table tbody {
  border-right: 1px solid #ebebeb;
}

.compare-table .table tbody tr:first-child {
  border-top: 1px solid #ebebeb;
}

.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
}

.compare-table .table tbody tr td.first-column {
  min-width: 180px;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin: 0;
  line-height: 1;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  padding-top: 70px;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  margin-bottom: 10px;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
  line-height: 24px;
  font-size: 14px;
}

.compare-table .table tbody tr td.pro-price,
.compare-table .table tbody tr td.pro-color,
.compare-table .table tbody tr td.pro-stock {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart {
  position: relative;
  background-color: #fb5d5d;
  color: #fff;
  border-radius: 0px;
  display: inline-block;
  width: 170px;
  padding: 14px 25px 14px 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart:hover {
  background-color: #000000;
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
  color: #000000;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 22px;
}

.compare-table .table tbody tr td.pro-remove button:hover {
  color: #fb5d5d;
}

.compare-table .table tbody tr td.pro-ratting i {
  font-size: 20px;
  color: #fdd835;
}

.single-my-account {
  margin-bottom: 20px;
  border: 1px solid #ebebeb;
}

.single-my-account .myaccount-info-wrapper {
  padding: 30px 20px;
  background-color: #fff;
}

.single-my-account .myaccount-info-wrapper .account-info-wrapper {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 28px;
  padding-bottom: 30px;
}

.single-my-account .myaccount-info-wrapper .account-info-wrapper h4 {
  font-size: 14px;
  margin: 0;
  text-transform: uppercase;
  color: #000000;
  font-weight: 600;
}

.single-my-account .myaccount-info-wrapper .account-info-wrapper h5 {
  font-size: 14px;
  letter-spacing: 0.2px;
  margin-top: 7px;
  font-weight: 600;
  color: #000000;
}

.single-my-account .myaccount-info-wrapper .account-info-wrapper .account-info-wrapper {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 28px;
  padding-bottom: 30px;
}

.single-my-account .myaccount-info-wrapper .billing-info {
  margin-bottom: 20px;
}

.single-my-account .myaccount-info-wrapper .billing-info label {
  color: #474747;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.single-my-account .myaccount-info-wrapper .billing-info input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  color: #474747;
  height: 40px;
  padding: 0 15px;
  width: 100%;
  outline: none;
}

.single-my-account .myaccount-info-wrapper .billing-back-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 26px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-my-account .myaccount-info-wrapper .billing-back-btn .billing-back a {
  color: #000000;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.single-my-account .myaccount-info-wrapper .billing-back-btn .billing-back a i {
  font-size: 15px;
  color: #fb5d5d;
}

.single-my-account .myaccount-info-wrapper .billing-back-btn .billing-back a:hover {
  color: #fb5d5d;
}

.single-my-account .myaccount-info-wrapper .billing-back-btn .billing-btn button {
  background-color: #f2f2f2;
  border: medium none;
  color: #000000;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 16px 35px 17px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  outline: none;
  border-radius: 0px;
}

.single-my-account .myaccount-info-wrapper .billing-back-btn .billing-btn button:hover {
  background-color: #fb5d5d;
  color: #fff;
}

.single-my-account .myaccount-info-wrapper .entries-wrapper {
  border: 1px solid #eaeaea;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .single-my-account .myaccount-info-wrapper .entries-wrapper {
    padding: 30px 10px;
  }
}

.single-my-account .myaccount-info-wrapper .entries-wrapper:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: 50%;
  top: 0;
  background-color: #eaeaea;
}

@media only screen and (max-width: 767px) {
  .single-my-account .myaccount-info-wrapper .entries-wrapper:before {
    display: none;
  }
}

.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-info {
  padding: 30px 20px;
}

@media only screen and (max-width: 767px) {
  .single-my-account .myaccount-info-wrapper .entries-wrapper .entries-info {
    padding: 0 10px 30px;
  }
}

.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-info p {
  color: #474747;
  font-size: 14px;
  margin: 0;
  text-transform: capitalize;
  line-height: 24px;
}

.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete a {
  background-color: #F32013;
  color: #fff;
  display: inline-block;
  line-height: 1;
  margin: 0 2px;
  padding: 12px 15px;
  text-transform: uppercase;
  font-weight: 600;
}

.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete a:hover {
  background-color: #fb5d5d;
}

.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete a.edit {
  background-color: #000000;
  font-weight: 600;
}

.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete a.edit:hover {
  background-color: #fb5d5d;
}

/*****************************
Pages - My Account
*****************************/
@media only screen and (max-width: 767px) {
  .dashboard_tab_button {
    margin-bottom: 50px;
  }
}

.dashboard_tab_button li {
  margin-bottom: 10px;
}

.dashboard_tab_button li:last-child {
  margin-bottom: 0;
}

.dashboard_tab_button li .nav-link {
  font-weight: 600;
  color: #fff;
  background-color: #000000;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
}

.dashboard_tab_button li .nav-link.active {
  background-color: #fb5d5d;
}

.dashboard_content h4 {
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 15px;
}

.dashboard_content h5 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dashboard_content p {
  font-size: 16px;
  line-height: 26px;
}

.dashboard_content p a {
  color: #fb5d5d;
  font-weight: 600;
}

.input-radio span {
  font-weight: 600;
  padding-right: 10px;
}

.input-radio span input[type=radio],
.account_login_form form span input[type=checkbox] {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  position: relative;
  top: 2px;
}

.checkbox-default-more-text em {
  margin-top: 14px;
  display: block;
  line-height: 24px;
}

.table_page table {
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
}

.table_page table thead {
  background: #f2f2f2;
}

.table_page table thead tr th {
  color: #24262b;
  border-bottom: 3px solid #fb5d5d;
  border-right: 1px solid #ededed;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 10px;
  text-align: center;
}

.table_page tbody tr td {
  border-right: 1px solid #ededed;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
  text-align: center;
  min-width: 150px;
}

.table_page tbody tr td a:hover {
  color: #fb5d5d;
}

.table_page table tbody tr td {
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  text-align: center;
  padding: 10px;
}

.table_desc table tbody tr td:last-child,
.table_page table thead tr:last-child th {
  border-right: 0;
}

.default-form-box {
  margin-bottom: 20px;
}

.default-form-box label {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  display: block;
}

.default-form-box input {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0px 10px 0px 20px;
  font-size: 16px;
  font-weight: 400;
  color: #474747;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  line-height: 45px;
}

.default-form-box input:focus {
  border-color: #fb5d5d;
}

.checkbox-default {
  line-height: 1;
  display: inline-block;
}

.checkbox-default input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  line-height: 0;
  height: auto;
}

.checkbox-default input::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #dee2e6;
  line-height: 20px;
  text-align: center;
  left: 0;
}

.checkbox-default span {
  display: inline-block;
  -webkit-transform: translateX(35px);
  transform: translateX(35px);
  text-transform: capitalize;
  color: #777 !important;
  font-size: 16px;
  cursor: pointer;
}

.checkbox-default input:checked::after {
  position: absolute;
  content: "";
  background: #fb5d5d;
  border-color: #fb5d5d;
  font-family: "FontAwesome";
  color: #fff;
}

.save_button button {
  background-color: #3b3b3b;
  border-color: #3b3b3b;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  width: 130px;
  height: 45px;
  line-height: 45px;
}

.save_button button:hover {
  border-color: #fb5d5d;
  background-color: #fb5d5d;
  color: #fff;
}

.login-register-wrapper .login-register-tab-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 40px;
}

.login-register-wrapper .login-register-tab-list a {
  position: relative;
}

.login-register-wrapper .login-register-tab-list a:before {
  background-color: #000;
  bottom: 5px;
  content: "";
  height: 18px;
  margin: 0 auto;
  position: absolute;
  right: -2px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  width: 1px;
}

.login-register-wrapper .login-register-tab-list a:hover h4 {
  color: #fb5d5d !important;
}

.login-register-wrapper .login-register-tab-list a h4 {
  font-size: 25px;
  font-weight: 600;
  margin: 0 20px;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: #000000;
}

.login-register-wrapper .login-register-tab-list a:last-child:before {
  display: none;
}

.login-register-wrapper .login-form-container {
  background: transparent none repeat scroll 0 0;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 80px;
  text-align: left;
}

.login-register-wrapper .login-form-container .login-register-form input {
  background-color: transparent;
  border: 1px solid #ebebeb;
  color: #474747;
  font-size: 14px;
  margin-bottom: 30px;
  padding: 0 15px;
  width: 100%;
  outline: none;
}

.login-register-wrapper .login-form-container .login-register-form input:focus {
  border-color: #fb5d5d;
}

.login-register-wrapper .login-form-container .login-register-form .button-box .login-toggle-btn {
  padding: 10px 0 19px;
}

.login-register-wrapper .login-form-container .login-register-form .button-box .login-toggle-btn input[type=checkbox] {
  height: 15px;
  margin: 0;
  position: relative;
  top: 1px;
  width: 17px;
}

.login-register-wrapper .login-form-container .login-register-form .button-box .login-toggle-btn a {
  color: #000000;
  float: right;
  font-size: 15px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.login-register-wrapper .login-form-container .login-register-form .button-box .login-toggle-btn a.flote-none {
  float: none !important;
}

.login-register-wrapper .login-form-container .login-register-form .button-box .login-toggle-btn a:hover {
  color: #fb5d5d;
}

.login-register-wrapper .login-form-container .login-register-form .button-box button {
  background-color: #f2f2f2;
  border: medium none;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 11px 30px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  outline: none;
  border-radius: 0px;
  font-family: "Nunito Sans", sans-serif;
}

.login-register-wrapper .login-form-container .login-register-form .button-box button:hover {
  background-color: #fb5d5d;
  color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-wrapper .login-form-container {
    padding: 80px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container {
    padding: 40px 15px;
  }
}

.login-register-wrapper .login-register-tab-list a.active h4 {
  color: #fb5d5d;
}

.cart-heading h2 {
  color: #000000;
  text-align: left;
  font-size: 24px;
  line-height: 16px;
  font-style: normal;
  text-transform: none;
  margin: 0 0 30px;
  font-weight: 600;
}

.empty-text-contant i {
  font-size: 60px;
  color: #000000;
}

.empty-text-contant h3 {
  font-size: 20px;
  color: #000000;
  margin: 20px 0 20px;
  font-weight: 600;
}

@media only screen and (max-width: 575px) {
  .empty-text-contant h3 {
    font-size: 18px;
  }
}

.empty-text-contant a.empty-cart-btn {
  padding: 15px 25px;
  display: inline-block;
  background: #fb5d5d;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-transform: uppercase;
}

.empty-text-contant a.empty-cart-btn:hover {
  background-color: #000000;
}

.empty-text-contant a.empty-cart-btn:hover i {
  -webkit-transform: translate(-5px, 0px);
  transform: translate(-5px, 0px);
}

.empty-text-contant a.empty-cart-btn i {
  font-size: 14px !important;
  color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: inline-block;
}

.privacy_policy_main_area .privacy_content {
  margin-bottom: 22px;
}

.privacy_policy_main_area .privacy_content h2 {
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 7px;
}

@media only screen and (max-width: 767px) {
  .privacy_policy_main_area .privacy_content h2 {
    font-size: 20px;
  }
}

.privacy_policy_main_area .privacy_content h3 {
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 7px;
}

.privacy_policy_main_area .privacy_content p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 19px;
}

.privacy_policy_main_area .privacy_content p a {
  color: #fb5d5d;
}

@media only screen and (max-width: 479px) {
  .privacy_policy_main_area .privacy_content p a {
    font-size: 12px;
  }
}

.privacy_policy_main_area .privacy_content p a:hover {
  color: #fb5d5d;
}

.privacy_policy_main_area .privacy_content.section_2 h2 {
  margin-bottom: 24px;
}

.privacy_policy_main_area .privacy_content.section_3:last-child {
  margin: 0;
}

.privacy_policy_main_area .privacy_content.section_3:last-child p {
  margin: 0;
}

/* 27. coming soon css here */
.coming_soon_area {
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  background-image: url(../images/coming-soon-bg/coming-soon.jpg);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.coming_soon_area::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0.15;
}

.coming_soon_title {
  text-align: center;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .coming_soon_title {
    margin-bottom: 36px;
  }
}

.coming_soon_title h2 {
  font-size: 37px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 600;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .coming_soon_title h2 {
    font-size: 22px;
    margin-bottom: 11px;
  }
}

.coming_soon_title p {
  color: #fff;
  font-size: 16px;
  max-width: 730px;
  margin: 0 auto;
  line-height: 26px;
}

@media only screen and (max-width: 575px) {
  .coming_soon_title p {
    font-size: 14px;
  }
}

.coming_soon_timing {
  text-align: center;
  margin: 57px 0 42px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming_soon_timing {
    margin: 50px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .coming_soon_timing {
    margin: 34px 0 22px;
  }
}

.coming_soon_timing span.cdown {
  display: inline-block;
  margin-right: 35px;
  background: inherit;
  font-size: 26px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  margin-bottom: 8px;
  width: 60px;
  height: 40px;
  line-height: 38px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding-top: 0;
  font-family: "Nunito Sans", sans-serif;
}

@media only screen and (max-width: 575px) {
  .coming_soon_timing span.cdown {
    margin-right: 20px;
    width: 55px;
    font-size: 20px;
  }
}

.coming_soon_timing span.cdown:last-child {
  margin-right: 0;
}

.coming_soon_timing span p {
  font-size: 14px;
  text-transform: capitalize;
  color: #fff;
}

.coming_soon_newsletter {
  text-align: center;
}

.coming_soon_newsletter .subscribe_form {
  margin-top: 0;
}

.coming_soon_newsletter .subscribe_form form {
  margin: 0 auto;
  width: 600px;
  background: inherit;
  border: 1px solid #efefef;
  border-radius: 50px;
  position: relative;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .coming_soon_newsletter .subscribe_form form {
    width: 100%;
  }
}

.coming_soon_newsletter .subscribe_form form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

.coming_soon_newsletter .subscribe_form form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.coming_soon_newsletter .subscribe_form form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.coming_soon_newsletter .subscribe_form form input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.coming_soon_newsletter .subscribe_form form input {
  color: #fff;
  font-size: 16px;
  padding: 0 140px 0 28px;
  background: inherit;
  height: 48px;
  border: 0;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .coming_soon_newsletter .subscribe_form form input {
    padding: 0 106px 0 18px;
    height: 45px;
  }
}

.coming_soon_newsletter .subscribe_form form button {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  background: inherit;
  color: #fff;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -24px;
  width: 120px;
  height: 48px;
}

.coming_soon_newsletter .subscribe_form form button:hover {
  color: #fb5d5d;
}

.coming_soon_newsletter .subscribe_form form button::before {
  content: "";
  background: #fff;
  width: 2px;
  position: absolute;
  bottom: 8px;
  top: 8px;
  left: 0;
  height: 30px;
}

@media only screen and (max-width: 767px) {
  .coming_soon_newsletter .subscribe_form form button {
    font-size: 13px;
  }
}

.coming_soon_newsletter h3 {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .coming_soon_newsletter h3 {
    letter-spacing: 1px;
  }
}

.coming_soon_newsletter .mailchimp-error {
  text-align: center;
  color: #fff;
}

.coming_soon_newsletter .mailchimp-success {
  text-align: center;
  color: #fff;
}

.coming_soon_logo {
  margin-bottom: 32px;
}

@media only screen and (max-width: 575px) {
  .coming_soon_logo {
    margin-bottom: 25px;
  }
}

.coming_soon_container {
  position: relative;
}

.faq-area .inner-descripe {
  margin-bottom: 30px;
}

.faq-area .inner-descripe h4 {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 15px;
  line-height: 28px;
}

@media only screen and (max-width: 767px) {
  .faq-area .inner-descripe h4 {
    font-size: 20px;
  }
}

.faq-area .inner-descripe p {
  color: #474747;
  line-height: 26px;
}

.faq-area .single-my-account h3.panel-title {
  border-bottom: 0;
  text-transform: capitalize;
}

.faq-area .single-my-account h3.panel-title a {
  font-size: 16px;
  color: #212121;
  display: block;
  padding: 16px 45px;
  position: relative;
  color: #000;
}

@media only screen and (max-width: 767px) {
  .faq-area .single-my-account h3.panel-title a {
    padding: 12px 38px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .faq-area .single-my-account h3.panel-title a {
    font-size: 13px;
  }
}

.faq-area .single-my-account h3.panel-title a:before {
  content: "";
  display: inline-block;
  font-family: "FontAwesome";
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -14px;
}

.faq-area .single-my-account h3.panel-title span {
  color: #212121;
  font-size: 14px;
  left: 20px;
  position: absolute;
  top: 10px;
  font-size: 16px;
  top: 14px;
}

@media only screen and (max-width: 767px) {
  .faq-area .single-my-account h3.panel-title span {
    top: 10px;
    font-size: 14px;
  }
}

.faq-area .single-my-account h3.panel-title a[aria-expanded=true] {
  color: #fb5d5d;
}

.faq-area .single-my-account .panel-body {
  padding: 20px;
  line-height: 26px;
}

.single-my-account h3.panel-title {
  background-color: #ebebeb;
  border-bottom: 1px solid #ebebeb;
  color: #212121;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  position: relative;
  text-transform: uppercase;
  line-height: 2;
}

.blank-page-area .blank-content-header {
  margin: 0 0 3rem;
}

.blank-page-area .blank-content-header h1 {
  color: #000000;
  font-size: 24px;
  line-height: 1;
  font-style: normal;
  text-transform: none;
  margin: 0;
  font-weight: 600;
  line-height: 22px;
}

@media only screen and (max-width: 767px) {
  .blank-page-area .blank-content-header h1 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .blank-page-area .blank-content-header h1 {
    font-size: 14px;
  }
}

.blank-page-area .page-not-found {
  -webkit-box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 1rem;
  font-size: 0.875rem;
  color: #474747;
  max-width: 570px;
  margin: 0 auto;
}

.blank-page-area .page-not-found h4 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0.5rem 0 1rem;
}

.blank-page-area .page-not-found p {
  color: #474747;
}

.blank-page-area .page-not-found a {
  color: #000000;
  font-weight: 700;
  margin: 10px 0;
  display: inline-block;
  text-transform: capitalize;
}

.blank-page-area .page-not-found a:hover {
  color: #fb5d5d;
}

.intro-right {
  position: absolute;
  top: auto;
  bottom: 0;
  right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .intro-right {
    width: 45%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .intro-right {
    display: none;
  }
}

.intro-right-image {
  max-width: 100%;
  display: block;
}

.intro-left {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .intro-left {
    display: none;
  }
}

.intro-left-image {
  max-width: 100%;
  display: block;
}

.about-intro-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  height: 528px;
  background-color: #fcf6f6;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .about-intro-area {
    height: 400px;
  }
}

@media only screen and (max-width: 575px) {
  .about-intro-area {
    height: 450px;
  }
}

@media only screen and (max-width: 479px) {
  .about-intro-area {
    height: 500px;
  }
}

.about-intro-content .title {
  margin: 0 0 21px;
  color: #3c3c3c;
  font-size: 48px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .about-intro-content .title {
    font-size: 32px;
  }
}

.about-intro-content p {
  line-height: 28px;
  color: #3c3c3c;
  margin: 0;
  padding-right: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .about-intro-content p {
    padding: 0;
  }
}

.service-left-image {
  max-width: 100%;
  display: block;
}

@media only screen and (max-width: 767px) {
  .service-left {
    margin-bottom: 50px;
  }
}

.service-area {
  background-color: #f0fcfc;
  padding: 60px 0;
}

.service-area .service-right-content {
  line-height: 1;
  margin-right: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .service-area .service-right-content {
    padding: 0;
  }
}

.service-area .service-right-content .sami-title {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 16px;
  color: #000000;
  display: inline-block;
}

.service-area .service-right-content .title {
  font-size: 36px;
  color: #000000;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0 0 15px;
  letter-spacing: 2.5px;
  line-height: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .service-area .service-right-content .title {
    font-size: 24px;
    line-height: 36px;
  }
}

.service-area .service-right-content p {
  line-height: 28px;
  color: #5a5a5a;
  margin: 0 0 41px;
}

.service-area .service-right-content .service-btn {
  font-size: 14px;
  width: 170px;
  height: 53px;
}

.team-wrapper .team-image img {
  max-width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.team-wrapper .team-content {
  text-align: center;
  line-height: 1;
}

.team-wrapper .team-content .title {
  font-size: 18px;
  color: #000000;
  font-weight: 600;
  margin: 27px 0 0;
  text-transform: uppercase;
}

.team-wrapper .team-content .sub-title {
  font-size: 14px;
  font-weight: 400;
  color: #656565;
  text-transform: uppercase;
  margin: 9px 0 27px;
  display: block;
}

.team-wrapper .team-social {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: 1px solid #d9d9d9;
}

.team-wrapper .team-social li+li {
  margin-left: 10px;
}

.team-wrapper .team-social li a {
  width: 42px;
  height: 42px;
  background-color: #2b2b2b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-top: -1px;
}

.team-wrapper .team-social li a::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  z-index: -1;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.team-wrapper .team-social li a:hover {
  color: #fff;
}

.team-wrapper .team-social li a:hover::before {
  opacity: 1;
}

.team-wrapper:hover .team-image img {
  -webkit-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}

.gmap_canvas {
  line-height: 0;
}

.gmap_canvas iframe {
  overflow: hidden;
  background: none !important;
  height: 600px;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gmap_canvas iframe {
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .gmap_canvas iframe {
    height: 350px;
  }
}

.contact-wrapper {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  padding: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-wrapper {
    padding: 70px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-wrapper {
    padding: 50px 30px;
  }
}

.contact-wrapper .contact-info .single-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 35px;
  border: 1px solid #e8e8e8;
  background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-wrapper .contact-info .single-contact {
    padding: 30px 28px;
  }
}

@media only screen and (max-width: 479px) {
  .contact-wrapper .contact-info .single-contact {
    padding: 25px 20px;
  }
}

.contact-wrapper .contact-info .single-contact .icon-box {
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  border-radius: 50%;
  margin-right: 30px;
}

.contact-wrapper .contact-info .single-contact .icon-box img {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 479px) {
  .contact-wrapper .contact-info .single-contact .icon-box {
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }
}

.contact-wrapper .contact-info .single-contact .info-box {
  width: calc(100% - 100px);
}

@media only screen and (max-width: 479px) {
  .contact-wrapper .contact-info .single-contact .info-box {
    width: calc(100% - 80px);
  }
}

.contact-wrapper .contact-info .single-contact .info-box .title {
  font-size: 24px;
  color: #2e2e2e;
  font-weight: 600;
  margin: 0 0 3px;
}

@media only screen and (max-width: 479px) {
  .contact-wrapper .contact-info .single-contact .info-box .title {
    font-size: 20px;
  }
}

.contact-wrapper .contact-info .single-contact .info-box p {
  font-size: 16px;
  color: #848484;
  font-weight: 400;
}

.contact-wrapper .contact-info .single-contact .info-box a {
  color: #848484;
}

.contact-wrapper .contact-info .single-contact .info-box a:hover {
  color: #fb5d5d;
}

.contact-wrapper .contact-info .single-contact:hover img {
  -webkit-transform: scale(0.9) rotate(3deg);
  transform: scale(0.9) rotate(3deg);
}

.contact-wrapper .contact-info .single-contact+.single-contact {
  margin-top: 30px;
}

.contact-form {
  padding-left: 30px;
}

.contact-form .contact-title {
  margin: 0 0 31px;
}

.contact-form .contact-title .title {
  font-size: 30px;
  color: #222121;
  font-weight: 700;
  margin: 0 0 14px;
}

.contact-form .contact-title p {
  color: #404040;
  line-height: 27px;
}

.contact-form .contact-form-style input,
.contact-form .contact-form-style textarea {
  background: transparent;
  border: 1px solid #c1c1c1;
  margin-bottom: 30px;
  color: #474747;
  font-size: 14px;
}

.contact-form .contact-form-style input::-webkit-input-placeholder,
.contact-form .contact-form-style textarea::-webkit-input-placeholder {
  font-style: italic;
  color: #999999;
}

.contact-form .contact-form-style input::-moz-placeholder,
.contact-form .contact-form-style textarea::-moz-placeholder {
  font-style: italic;
  color: #999999;
}

.contact-form .contact-form-style input:-ms-input-placeholder,
.contact-form .contact-form-style textarea:-ms-input-placeholder {
  font-style: italic;
  color: #999999;
}

.contact-form .contact-form-style input::-ms-input-placeholder,
.contact-form .contact-form-style textarea::-ms-input-placeholder {
  font-style: italic;
  color: #999999;
}

.contact-form .contact-form-style input::placeholder,
.contact-form .contact-form-style textarea::placeholder {
  font-style: italic;
  color: #999999;
}

.contact-form .contact-form-style input:focus,
.contact-form .contact-form-style textarea:focus {
  border-color: #fb5d5d;
}

.contact-form .contact-form-style textarea {
  margin-bottom: 0;
  height: 200px;
}

.contact-form p {
  color: #474747;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form {
    padding: 50px 27px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form {
    padding: 50px 0px 0px;
  }
}

.contact-form button {
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 1;
  font-weight: 600;
}

.contact-form button i {
  font-size: 16px;
  margin-left: 10px;
}

.btn {
  padding: 0;
  font-size: 16px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  font-weight: 700;
  line-height: 1;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary {
  color: #fff;
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%) !important;
  text-transform: uppercase;
  z-index: 1;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #fd7863 !important;
}

.load-more-btn {
  color: #fff;
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%) !important;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  z-index: 1;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #fd7863 !important;
  text-decoration: none;
  border-radius: 16px;
  margin-top: 20px;
}

.load-more-btn:hover {
  color: #fff;
}

.btn-primary::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(155deg, #fd3f6b 0%, #fd7863 98%, #f3dfe0 100%);
  z-index: -1;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.btn-primary:hover {
  color: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.btn-primary:hover::before {
  opacity: 1;
}

.btn-primary.blog-btn {
  background: linear-gradient(155deg, #343434 100%, #343434 100%, #343434 100%);
  width: 119px;
  height: 35px;
  font-size: 11px;
}

.btn-primary.blog-btn::before {
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
}

.btn-primary.blog-btn i {
  margin-left: 5px;
}

.shape {
  background-color: #343434;
  position: absolute;
  left: 405px;
  top: 4531px;
  width: 119px;
  height: 35px;
  z-index: 131;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  color: #fff;
}

.btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary,
.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
}

.shape {
  background-image: -ms-linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  position: absolute;
  left: 375px;
  top: 649px;
  width: 210px;
  height: 65px;
  z-index: 523;
}

.category-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.category-wrapper .category-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  height: 180px;
  border: 2px solid #474747;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  margin: auto;
}

@media only screen and (max-width: 479px) {
  .category-wrapper .category-inner {
    width: 140px;
    height: 140px;
  }
}

.category-wrapper .category-inner:after {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 100%;
  z-index: -1;
  border: 2px dashed #fb5d5d;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.category-wrapper .category-inner .category-single-item {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: center;
}

.category-wrapper .category-inner .category-single-item img {
  margin: auto;
}

@media only screen and (max-width: 479px) {
  .category-wrapper .category-inner .category-single-item img {
    max-width: 100%;
  }
}

.category-wrapper .category-inner .category-single-item .title {
  display: block;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  color: #474747;
  margin: 12px 0 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 479px) {
  .category-wrapper .category-inner .category-single-item .title {
    font-size: 13px;
  }
}

.category-wrapper .category-inner:hover {
  border-color: transparent;
}

.category-wrapper .category-inner:hover:after {
  opacity: 1;
  -webkit-animation: spinAround 9s linear infinite;
  animation: spinAround 9s linear infinite;
}

.category-wrapper .category-inner:hover .title {
  color: #fb5d5d;
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Product Tab Nav */
/* .nav-center .product-tab-nav {
  margin: 25px 0 60px;
} */

@media only screen and (max-width: 479px) {
  .nav-center .product-tab-nav {
    margin: 10px 0 10px;
  }
}

.nav-center .product-tab-nav .nav-item {
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  text-transform: uppercase;
  padding: 0;
  cursor: pointer;
  font-weight: 600;
}

@media only screen and (max-width: 479px) {
  .nav-center .product-tab-nav .nav-item {
    margin: 0px 0px 20px 15px;
  }
}

.nav-center .product-tab-nav .nav-item:last-child {
  margin-right: 0;
}

.nav-center .product-tab-nav .nav-item .nav-link {
  padding: 5px 15px;
  color: #5f5f5f;
  background-color: #f8f8f8;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
  border-radius: 5px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.nav-center .product-tab-nav .nav-item .nav-link::after {
  display: none;
}

.nav-center .product-tab-nav .nav-item .nav-link:hover,
.nav-center .product-tab-nav .nav-item .nav-link.active {
  color: #fff;
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
}

.mb-n-30px {
  margin-bottom: -30px;
}

.product-tab-nav {
  margin: 9px 0 0;
}

@media only screen and (max-width: 767px) {
  .product-tab-nav {
    margin: 9px 0 15px;
  }
}

.product-tab-nav .nav-item {
  margin-right: 50px;
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  text-transform: capitalize;
  padding: 0;
  cursor: pointer;
  font-weight: 600;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

@media only screen and (max-width: 575px) {
  .product-tab-nav .nav-item {
    margin-right: 30px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 479px) {
  .product-tab-nav .nav-item {
    margin-right: 25px;
    font-size: 16px;
  }
}

.product-tab-nav .nav-item:last-child {
  margin-right: 0;
}

.product-tab-nav .nav-item .nav-link {
  color: #a7a7a7;
  padding: 0;
  position: relative;
}

.header-padding {
  padding: 0px 30px;
}

.product-tab-nav .nav-item .nav-link::after {
  position: absolute;
  top: auto;
  bottom: -5px;
  height: 2px;
  width: 0%;
  content: "";
  background-color: transparent;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  left: 0;
}

.product-tab-nav .nav-item .nav-link:hover,
.product-tab-nav .nav-item .nav-link.active {
  color: #fb5d5d;
}

.product-tab-nav .nav-item .nav-link:hover::after,
.product-tab-nav .nav-item .nav-link.active::after {
  background-color: #fb5d5d;
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .col-xs-6 {
    width: 50%;
  }
}

@media only screen and (max-width: 479px) {
  .col-xs-6 {
    width: 100%;
  }
}

.tab-content.top-borber {
  border-top: 1px solid #ececec;
  padding-top: 60px;
}

/* Product Style */
.product {
  overflow: hidden;
}

.product .thumb {
  position: relative;
  overflow: hidden;
}

.product .thumb .image {
  position: relative;
  display: block;
  overflow: hidden;
}

.product .thumb .image img {
  z-index: 1;
  max-width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.product .thumb .image img.hover-image {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 0;
}

.product .thumb .badges {
  position: absolute;
  z-index: 8;
  top: 18px;
  left: -2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.product .thumb .badges span {
  font-size: 12px;
  line-height: 1.6;
  display: block;
  padding: 0 8px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
}

.product .thumb .badges span+span {
  margin-top: 10px;
}

.product .thumb .badges span.new {
  background-color: #000000;
}

.product .thumb .badges span.sale {
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
}

.product .thumb .actions {
  position: absolute;
  z-index: 9;
  top: 18px;
  right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.product .thumb .actions .action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 30px;
  height: 30px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  color: #000000;
  border-radius: 50%;
  background-color: transparent;
  font-size: 18px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.product .thumb .actions .action::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  z-index: -1;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.product .thumb .actions .action:hover {
  color: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.product .thumb .actions .action:hover::before {
  opacity: 1;
}

.product .thumb .actions .action+.action {
  margin-top: 10px;
}

.product .thumb .actions .action:not(.wishlist) {
  visibility: hidden;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.product .thumb .actions .action.active {
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
}

.product .thumb .add-to-cart {
  display: block;
  width: 40%;
  line-height: 30px;
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  text-align: center;
  border: 0;
  border-radius: 30px;
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 11;
  margin: auto;
  font-weight: 600;
  visibility: hidden;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  color: #fff;
  opacity: 0;
  font-family: "Nunito Sans", sans-serif;
  text-transform: uppercase;
  overflow: hidden;
}

.product .thumb .add-to-cart::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  z-index: -1;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.product .thumb .add-to-cart:hover {
  color: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.product .thumb .add-to-cart:hover::before {
  opacity: 1;
}

.product .content {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px 0px 0px 0px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fff;
}

.product .content .ratings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 4px;
}

.product .content .ratings .rating-wrap {
  font-size: 14px;
  line-height: 1;
  position: relative;
  color: #e4e4e4;
  white-space: nowrap;
}

.product .content .ratings .rating-wrap::before {
  font-family: "FontAwesome";
  content: "    ";
}

.product .content .ratings .rating-wrap .star {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  color: #ffde00;
}

.product .content .ratings .rating-wrap .star::before {
  font-family: "FontAwesome";
  content: "    ";
}

.product .content .ratings .rating-num {
  font-size: 14px;
  line-height: 1;
  margin-left: 6px;
  color: #9f9e9e;
}

.product .content .title {
  font-size: 16px;
  margin: 5px 0 5px;
  font-family: "Nunito Sans", sans-serif;
}

.product .content .title a {
  text-decoration: none;
  color: #1d1d1d;
  font-size: 16px;
}

.product .content .price {
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  line-height: 1;
}

.product .content .price:not(:last-child) {
  margin-bottom: 20px;
}

.product .content .price span.new {
  color: #1d1d1d;
  font-weight: 600;
}

.product .content .price span.old {
  font-size: 14px;
  margin-left: 10px;
  text-decoration: line-through;
  color: #9f9e9e;
}

.product .content .btn {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.product:hover .thumb .image img {
  -webkit-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}

.product:hover .thumb .image img:not(:last-child) {
  opacity: 0;
}

.product:hover .thumb .image img.hover-image {
  opacity: 1;
}

.product:hover .thumb .actions .action {
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.product:hover .thumb .add-to-cart {
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.product:hover .content .title a {
  color: #474747;
}

.product:hover .content .title a:hover {
  color: #fb5d5d;
}

.tab-content .tab-pane {
  display: block;
  overflow: hidden;
  height: 0;
  visibility: hidden;
  max-width: 100%;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/* Banner Area Style */
.single-col {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 37.5%;
}

@media only screen and (max-width: 767px) {
  .single-col {
    width: 100%;
  }
}

.single-col .single-banner {
  position: relative;
  display: block;
  overflow: hidden;
}

.single-col .single-banner img {
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-col .single-banner:hover img {
  -webkit-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}

.single-col .single-banner .item-disc {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 60px 45px;
  line-height: 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-col .single-banner .item-disc {
    padding: 30px 20px;
  }
}

.single-col .single-banner .item-disc .item-title {
  font-size: 24px;
  color: #323232;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  margin: 0 0 14px;
}

.single-col .single-banner .item-disc .item-amount {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #575656;
  text-transform: capitalize;
}

.single-col.center-col {
  width: 24.9%;
}

@media only screen and (max-width: 767px) {
  .single-col.center-col {
    width: 100%;
    margin: 30px 0;
  }
}

.single-col.center-col .single-banner .item-disc {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-col.center-col .single-banner .title {
  font-size: 30px;
  color: #fb5d5d;
  font-weight: 800;
  margin: 0 0 19px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-col.center-col .single-banner .title {
    font-size: 20px;
    margin: 0 0 10px;
  }
}

.single-col.center-col .single-banner .shop-link {
  font-size: 18px;
  color: #161616;
  font-weight: 600;
  position: relative;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-col.center-col .single-banner .shop-link {
    font-size: 16px;
  }
}

.single-col.center-col .single-banner .shop-link::after {
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #858585;
  content: "";
  left: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-col.center-col .single-banner .shop-link:hover {
  color: #fb5d5d;
}

.single-col.center-col .single-banner .shop-link:hover::after {
  background-color: #fb5d5d;
}

.plr-15px {
  padding: 0 15px;
}

@media only screen and (max-width: 479px) {
  .plr-15px {
    padding: 0;
  }
}

.single-banner-2 {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.single-banner-2 img {
  max-width: 100%;
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-banner-2 .item-disc {
  position: absolute;
  top: auto;
  left: 40px;
  bottom: 57px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-banner-2 .item-disc {
    bottom: 30px;
    left: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-2 .item-disc {
    bottom: 30px;
    left: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .single-banner-2 .item-disc {
    bottom: 30px;
    left: 30px;
  }
}

.single-banner-2 .item-disc .title {
  color: #323232;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-2 .item-disc .title {
    font-size: 20px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .single-banner-2 .item-disc .title {
    font-size: 20px;
    line-height: 30px;
  }
}

.single-banner-2 .item-disc .shop-link {
  font-size: 11px;
  width: 114px;
  height: 35px;
  margin: 21px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-2 .item-disc .shop-link {
    margin: 15px 0 0;
  }
}

@media only screen and (max-width: 479px) {
  .single-banner-2 .item-disc .shop-link {
    margin: 15px 0 0;
  }
}

.single-banner-2:hover img {
  -webkit-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}

.single-blog {
  position: relative;
}

.single-blog .blog-image {
  overflow: hidden;
}

.single-blog .blog-image img {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-blog:hover .blog-image img {
  -webkit-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-blog .blog-text {
  padding: 30px 20px 40px 30px;
  background-color: #fcf6f6;
}

.single-blog .blog-text .blog-heading {
  margin: 15px 0px 21px 0px;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-blog .blog-text .blog-heading {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-blog .blog-text .blog-heading {
    font-size: 18px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .single-blog .blog-text .blog-heading {
    font-size: 21px;
    line-height: 32px;
  }
}

.single-blog .blog-text .blog-heading .blog-heading-link {
  color: #2d2d2d;
}

.single-blog .blog-text .blog-heading .blog-heading-link:hover {
  color: #fb5d5d;
}

.single-blog .blog-text .blog-detail-text {
  margin: 10px 0;
}

.single-blog .blog-text p {
  margin-bottom: 25px;
}

.single-blog .blog-athor-date a+a {
  margin-left: 28px;
  position: relative;
}

.single-blog .blog-athor-date a+a::after {
  position: absolute;
  top: 0;
  left: -17px;
  width: 2px;
  height: 21px;
  content: "";
  background-color: #fb5d5d;
}

.single-blog .blog-athor-date a {
  color: #454545;
  font-weight: 600;
  display: inline-block;
  font-size: 14px;
}

.single-blog .blog-athor-date a:hover {
  color: #fb5d5d;
}

.single-blog .blog-athor-date a i {
  color: #fb5d5d;
  margin-right: 5px;
}

.blog-post-content-inner .blog-title {
  font-size: 36px;
  font-weight: 700;
  margin: 15px 0px 10px;
  line-height: 1.4;
  color: #2d2d2d;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-post-content-inner .blog-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-post-content-inner .blog-title {
    font-size: 28px;
  }
}

.blog-post-content-inner .blog-athor-date a+a {
  margin-left: 28px;
  position: relative;
}

.blog-post-content-inner .blog-athor-date a+a::after {
  position: absolute;
  top: 0;
  left: -17px;
  width: 2px;
  height: 21px;
  content: "";
  background-color: #fb5d5d;
}

.blog-post-content-inner .blog-athor-date a {
  color: #454545;
  font-weight: 600;
  display: inline-block;
  font-size: 16px;
}

.blog-post-content-inner .blog-athor-date a:hover {
  color: #fb5d5d;
}

.blog-post-content-inner .blog-athor-date a i {
  color: #fb5d5d;
  margin-right: 5px;
}

.blog-post-content-inner p {
  line-height: 30px;
  margin: 20px 0 8px;
  line-height: 30px;
  color: #777777;
}

.single-post-content p.quate-speech {
  padding: 40px 45px;
  font-size: 30px;
  line-height: 44px;
  font-style: italic;
  color: #fb5d5d;
  background-color: #f9f9f9;
  position: relative;
  text-align: center;
  margin: 40px 0;
  font-weight: 600;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .single-post-content p.quate-speech {
    font-size: 22px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .single-post-content p.quate-speech {
    font-size: 20px;
    line-height: 32px;
    padding: 30px 25px;
  }
}

@media only screen and (max-width: 575px) {
  .single-post-content p.quate-speech {
    padding: 30px 20px;
    font-size: 18px;
    line-height: 28px;
  }
}

.single-post-content p.quate-speech::after {
  position: absolute;
  top: auto;
  left: auto;
  right: 0px;
  bottom: 0px;
  content: "";
  background-image: url(../images/blog-image/quate-icon.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  width: 111px;
  height: 69px;
}

.single-post-content .title {
  font-size: 36px;
  color: #2d2d2d;
  margin: 20px 0 30px;
  display: block;
  font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .single-post-content .title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .single-post-content .title {
    font-size: 28px;
  }
}

.single-post-content P {
  color: #777777;
  line-height: 30px;
  margin-bottom: 30px;
}

.single-post-content P+p {
  margin-bottom: 40px;
}

.blog-sidebar.mr-20px {
  margin-right: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .blog-sidebar.mr-20px {
    margin-right: 0px;
  }
}

.blog-sidebar.ml-20px {
  margin-left: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .blog-sidebar.ml-20px {
    margin-left: 0px;
  }
}

.blog-sidebar .sidebar-widget {
  background-color: #fcf6f6;
  padding: 35px 30px 40px 30px;
  line-height: 1;
  border-radius: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-sidebar .sidebar-widget {
    padding: 25px 20px 30px 20px;
  }
}

.blog-sidebar .sidebar-widget .sidebar-title {
  font-size: 24px;
  color: #222121;
  font-weight: 700;
  margin: 0 0 24px;
}

.blog-sidebar .sidebar-widget+.sidebar-widget {
  margin-top: 60px;
}

.blog-sidebar .search-widget {
  position: relative;
}

.blog-sidebar input {
  border: 1px solid #d7d7d7;
  padding-right: 70px;
  font-size: 14px;
  border-radius: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-sidebar input {
    font-size: 12px;
    padding-left: 10px;
  }
}

.blog-sidebar input:focus {
  border-color: #fb5d5d;
}

.blog-sidebar input::-webkit-input-placeholder {
  color: #919090;
  font-style: italic;
}

.blog-sidebar input::-moz-placeholder {
  color: #919090;
  font-style: italic;
}

.blog-sidebar input:-ms-input-placeholder {
  color: #919090;
  font-style: italic;
}

.blog-sidebar input::-ms-input-placeholder {
  color: #919090;
  font-style: italic;
}

.blog-sidebar input::placeholder {
  color: #919090;
  font-style: italic;
}

.blog-sidebar button {
  position: absolute;
  width: 64px;
  left: auto;
  right: 0;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  border-radius: 0 5px 5px 0;
  border-color: #d9d9d9;
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  color: #fff;
  padding: 0;
  z-index: 1;
  overflow: hidden;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog-sidebar button::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  z-index: -1;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.blog-sidebar button:hover {
  color: #fff;
}

.blog-sidebar button:hover::before {
  opacity: 1;
}

.blog-sidebar .category-post li {
  margin-bottom: 10px;
  display: block;
}

.blog-sidebar .category-post li a {
  padding: 17px 20px;
  position: relative;
  display: block;
  color: #494949;
  background-color: #ffffff;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog-sidebar .category-post li a::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  z-index: -1;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.blog-sidebar .category-post li a:hover {
  color: #fff;
}

.blog-sidebar .category-post li a:hover::before {
  opacity: 1;
}

.blog-sidebar .category-post li a span {
  float: right;
}

.blog-sidebar .category-post li:last-child {
  margin: 0;
}

.blog-sidebar .recent-post-widget .recent-single-post .thumb-side img {
  width: 98px;
  height: 75px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-sidebar .recent-post-widget .recent-single-post .thumb-side img {
    width: 70px;
  }
}

.blog-sidebar .recent-post-widget .recent-single-post .media-side {
  margin: 0 0 0 18px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}

.blog-sidebar .recent-post-widget .recent-single-post .media-side a {
  font-size: 18px;
  font-weight: 700;
  color: #383838;
  line-height: 25px;
}

.blog-sidebar .recent-post-widget .recent-single-post .media-side a:hover {
  color: #fb5d5d;
}

.blog-sidebar .recent-post-widget .recent-single-post .media-side span {
  font-size: 14px;
  color: #454545;
  display: block;
  margin: 0 0 10px;
}

.blog-sidebar .recent-post-widget .recent-single-post .media-side span i {
  color: #fb5d5d;
  margin-right: 5px;
}

.blog-sidebar .recent-post-widget .recent-single-post+.recent-single-post {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #dddddd;
}

.blog-sidebar .sidebar-widget-tag ul li {
  line-height: 1;
  display: inline-block;
  margin: 8px 1px;
}

.blog-sidebar .sidebar-widget-tag ul li a {
  display: block;
  float: left;
  padding: 10px 15px;
  font-size: 16px;
  color: #7e7e7e;
  font-weight: 400;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: transparent;
  text-transform: capitalize;
}

.blog-sidebar .sidebar-widget-tag ul li a:hover {
  background-color: #fb5d5d;
  color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-sidebar .sidebar-widget-tag ul li a {
    font-size: 14px;
  }
}

.blog-post-audio {
  line-height: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-post-audio.sidebar iframe {
    height: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-post-audio.sidebar iframe {
    height: 228px;
  }
}

.blog-image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.blog-post-media {
  overflow: hidden;
}

.blog-post-media .blog-image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.blog-post-media iframe {
  height: 250px;
  width: 100%;
  position: relative;
  border: 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-post-media iframe {
    height: 308px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-post-media iframe {
    height: 228px;
  }
}

.blog-post-media .blog-post-video .thumb-image {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.blog-post-media .blog-post-video .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.blog-post-media .blog-gallery .swiper-slide img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.blog-single-tags-share {
  margin-bottom: 40px;
  line-height: 24px;
  background-color: #f9f9f9;
  padding: 13px 20px;
}

@media only screen and (max-width: 575px) {
  .blog-single-tags-share {
    padding: 20px 20px;
  }
}

.blog-single-tags-share span.title {
  font-weight: 600;
  color: #5f5f5f;
}

.blog-single-tags-share span.title i {
  margin-left: 5px;
}

.blog-single-tags-share span.title a {
  color: #000000;
}

.blog-single-tags-share span.title a:hover {
  color: #fb5d5d;
}

.blog-single-tags-share .tag-list li {
  display: inline-block;
  margin-left: 5px;
}

.blog-single-tags-share .tag-list li a {
  color: #9f9e9e;
}

.blog-single-tags-share .tag-list li a:hover {
  color: #fb5d5d;
}

.blog-single-tags-share .social li {
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  color: #000000;
}

.blog-single-tags-share .social li a {
  color: #000000;
}

.blog-single-tags-share .social li a:hover {
  color: #fb5d5d;
}

.blog-nav a {
  width: 98px;
  height: 75px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 30px;
  color: #010101;
  background-color: #ededed;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .blog-nav a {
    width: 60px;
    height: 60px;
  }
}

.blog-nav a::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  z-index: -1;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.blog-nav a:hover {
  color: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.blog-nav a:hover::before {
  opacity: 1;
}

.comment-area {
  line-height: 24px;
  margin-top: 100px;
}

@media only screen and (max-width: 767px) {
  .comment-area {
    margin-top: 50px;
  }
}

.comment-area .comment-heading {
  font-size: 30px;
  text-transform: capitalize;
  margin: 0 0 50px;
  font-weight: 700;
  color: #222121;
}

@media only screen and (max-width: 575px) {
  .comment-area .comment-heading {
    font-size: 24px;
  }
}

.comment-area .single-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 60px;
}

@media only screen and (max-width: 767px) {
  .comment-area .single-review {
    display: block;
  }
}

.comment-area .single-review .review-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  margin: 0 15px 0 0;
}

.comment-area .single-review .review-img img {
  border-radius: 10px;
}

@media only screen and (max-width: 767px) {
  .comment-area .single-review .review-img {
    margin: 0 0 20px 0;
  }
}

.comment-area .single-review .review-content {
  padding-left: 15px;
}

.comment-area .single-review .review-content .review-top-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.comment-area .single-review .review-content .review-top-wrap .review-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.comment-area .single-review .review-content .review-top-wrap .review-left .review-name .title {
  font-size: 24px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .comment-area .single-review .review-content .review-top-wrap .review-left .review-name .title {
    font-size: 20px;
  }
}

.comment-area .single-review .review-content .review-top-wrap .review-left .review-name span {
  font-weight: 700;
  color: #666666;
}

.comment-area .single-review .review-content .review-top-wrap .review-left a {
  color: #474747;
}

.comment-area .single-review .review-content .review-top-wrap .review-left a:hover {
  color: #fb5d5d;
}

.comment-area .single-review .review-content p {
  margin: 0;
  line-height: 30px;
  color: #777777;
}

.comment-area .single-review .review-content .review-left a {
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  width: 100px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: linear-gradient(155deg, #343434 100%, #343434 100%, #343434 100%);
  border-radius: 30px;
  line-height: 1;
  margin-top: 20px;
}

.comment-area .single-review .review-content .review-left a::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  opacity: 0;
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
}

.comment-area .single-review .review-content .review-left a:hover {
  color: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.comment-area .single-review .review-content .review-left a:hover::before {
  opacity: 1;
}

.comment-area .single-review .review-content .review-left a i {
  font-size: 11px;
  margin-left: 5px;
}

.comment-area .single-review.child-review {
  margin-left: 70px;
}

@media only screen and (max-width: 767px) {
  .comment-area .single-review.child-review {
    margin-left: 0px;
  }
}

.blog-comment-form {
  line-height: 24px;
  margin-top: 100px;
}

@media only screen and (max-width: 767px) {
  .blog-comment-form {
    margin-top: 50px;
  }
}

.blog-comment-form .comment-heading {
  font-size: 30px;
  text-transform: capitalize;
  margin: 0 0 20px;
  font-weight: 700;
  color: #222121;
}

@media only screen and (max-width: 575px) {
  .blog-comment-form .comment-heading {
    font-size: 24px;
  }
}

.blog-comment-form .single-form {
  margin-top: 30px;
}

.blog-comment-form .single-form input {
  border: 1px solid #d7d7d7;
  font-size: 14px;
  font-style: italic;
}

.blog-comment-form .single-form input::-webkit-input-placeholder {
  color: #999999;
}

.blog-comment-form .single-form input::-moz-placeholder {
  color: #999999;
}

.blog-comment-form .single-form input:-ms-input-placeholder {
  color: #999999;
}

.blog-comment-form .single-form input::-ms-input-placeholder {
  color: #999999;
}

.blog-comment-form .single-form input::placeholder {
  color: #999999;
}

.blog-comment-form .single-form input:focus {
  border-color: #fb5d5d;
}

.blog-comment-form .single-form textarea {
  height: 180px;
  border: 1px solid #d7d7d7;
}

.blog-comment-form .single-form textarea:focus {
  border-color: #fb5d5d;
}

.blog-comment-form button {
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 1;
  font-weight: 600;
}

.blog-comment-form button i {
  font-size: 16px;
  margin-left: 10px;
}

.pro-pagination-style li {
  display: inline-block;
}

.pro-pagination-style li+li {
  margin-left: 10px;
}

.pro-pagination-style a {
  font-weight: 400;
  color: #1d1d1d;
  padding: 0;
  height: 40px;
  line-height: 40px;
  background: #f6f6f6;
  font-size: 14px;
  display: inline-block;
  width: 40px;
  border-radius: 100%;
  text-align: center;
  vertical-align: top;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.pro-pagination-style a.active {
  color: #fff;
  background-color: #fb5d5d;
  -webkit-box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  border-color: #fb5d5d;
}

.pro-pagination-style a:hover {
  color: #fff;
  background-color: #fb5d5d;
  -webkit-box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  border-color: #fb5d5d;
}

/* feature section style*/
.feature-area {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.feature-area.mt-n-65px {
  /* margin-top: -65px; */
  padding-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-area.mt-n-65px {
    margin-top: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-area.mt-n-65px {
    margin-top: 65px;
  }
}

.single-feature {
  padding: 15px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e8e8e8;
  background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-feature {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 479px) {
  .single-feature {
    padding: 25px;
  }
}

.single-feature .feature-icon {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  border-radius: 50%;
  margin-right: 30px;
}

.single-feature h4 {
  font-size: 18px;
  margin-bottom: 0rem !important;
}

.single-feature .feature-icon img {
  width: 30px !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (max-width: 479px) {
  .single-feature .feature-icon {
    margin-right: 20px;
  }
}

.single-feature .feature-content {
  width: calc(100% - 100px);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (max-width: 479px) {
  .single-feature .feature-content {
    width: calc(100% - 90px);
  }
}

.single-feature .feature-content .title {

  font-size: 24px;
  color: #2e2e2e;
  font-weight: 600;
  margin: 0 0 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (max-width: 479px) {
  .single-feature .feature-content .title {
    font-size: 20px;
  }
}

.single-feature .feature-content .sub-title {
  font-size: 14px;
  color: #848484;
  font-weight: 400;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (max-width: 479px) {
  .single-feature .feature-content .sub-title {
    font-size: 14px;
  }
}

.single-feature img {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-feature:hover img {
  -webkit-transform: scale(0.9) rotate(3deg);
  transform: scale(0.9) rotate(3deg);
}

.slider-nav-style-1 {
  position: relative;
}

.slider-nav-style-1 .swiper-button-next:after {
  content: "";
}

.slider-nav-style-1 .swiper-button-prev:after {
  content: "";
}

.slider-nav-style-1 .swiper-button-next:after,
.slider-nav-style-1 .swiper-button-prev:after {
  font-family: "Pe-icon-7-stroke";
  font-size: 40px;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
  outline: 0;
}

.slider-nav-style-1.small-nav .swiper-button-next:after,
.slider-nav-style-1.small-nav .swiper-button-prev:after {
  font-size: 30px;
}

.slider-nav-style-1 .swiper-buttons .swiper-button-next,
.slider-nav-style-1 .swiper-buttons .swiper-button-prev {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  width: 50px;
  height: 50px;
  line-height: 50px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: auto;
  border-radius: 50%;
  text-align: center;
  -webkit-box-shadow: 0 3px 25.5px 4.5px rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 25.5px 4.5px rgba(0, 0, 0, 0.06);
  color: #000;
  background-color: #fff;
}

.slider-nav-style-1 .swiper-buttons .swiper-button-next:hover,
.slider-nav-style-1 .swiper-buttons .swiper-button-prev:hover {
  background-color: #fb5d5d;
  color: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
  box-shadow: 0 5px 15px 0 rgba(153, 63, 107, 0.5);
}

.slider-nav-style-1.small-nav .swiper-buttons .swiper-button-next,
.slider-nav-style-1.small-nav .swiper-buttons .swiper-button-prev {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.slider-nav-style-1.small-nav .swiper-buttons .swiper-button-next:hover,
.slider-nav-style-1.small-nav .swiper-buttons .swiper-button-prev:hover {
  background-color: #fb5d5d;
  color: #fff;
}

.slider-nav-style-1 .swiper-button-prev {
  outline: 0;
  right: auto;
  left: 30px;
}

@media only screen and (max-width: 767px) {
  .slider-nav-style-1 .swiper-button-prev {
    left: 15px;
  }
}

.slider-nav-style-1.small-nav .swiper-button-prev {
  outline: 0;
  right: auto;
  left: 10px;
}

@media only screen and (max-width: 767px) {
  .slider-nav-style-1.small-nav .swiper-button-prev {
    left: 5px;
  }
}

.slider-nav-style-1 .swiper-button-next {
  outline: 0;
  left: auto;
  right: 30px;
}

@media only screen and (max-width: 767px) {
  .slider-nav-style-1 .swiper-button-next {
    right: 15px;
  }
}

.slider-nav-style-1.small-nav .swiper-button-next {
  outline: 0;
  left: auto;
  right: 10px;
}

@media only screen and (max-width: 767px) {
  .slider-nav-style-1.small-nav .swiper-button-next {
    right: 5px;
  }
}

.slider-nav-style-1:hover .swiper-button-next,
.slider-nav-style-1:hover .swiper-button-prev {
  opacity: 1;
  visibility: visible;
}

.slider-nav-style-1:hover .swiper-button-next {
  right: 50px;
}

.slider-nav-style-1:hover .swiper-button-prev {
  left: 50px;
}

.slider-nav-style-1.small-nav:hover .swiper-button-next {
  right: 20px;
}

.slider-nav-style-1.small-nav:hover .swiper-button-prev {
  left: 20px;
}

/*===================================
    - Breadvrumb  
=================================*/
.breadcrumb-area {
  background-color: #fcf6f6;
  background-repeat: no-repeat;
  background-position: center center;
  border-bottom-width: 0px;
  padding-top: 110px;
  padding-bottom: 117px;
  background-size: cover;
  line-height: 1;
}

@media only screen and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .breadcrumb-area {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.breadcrumb-area .breadcrumb-title {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 48px;
  color: #3c3c3c;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area .breadcrumb-title {
    font-size: 36px;
  }
}

.breadcrumb-list li {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
  color: #3c3c3c;
  margin-top: 14px;
}

.breadcrumb-list li a {
  color: #3c3c3c;
}

.breadcrumb-list li a:hover {
  color: #fb5d5d;
}

.breadcrumb-list li.active {
  color: #3c3c3c;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 5px;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 10px;
  color: #3c3c3c;
  content: "//";
}

.page-pagination li {
  display: inline-block;
}

.page-pagination li a {
  font-weight: 500;
  padding: 0 10px;
  display: block;
  text-align: center;
  line-height: 41px;
  min-width: 41px;
  height: 41px;
  text-transform: uppercase;
  color: #ababab;
  letter-spacing: 2px;
  border-radius: 5px;
}

.page-pagination li a.current {
  background: #f6f5f9;
  color: #fb5d5d;
}

.page-pagination li a:hover {
  color: #fb5d5d;
}

/* Deal Area Style */
.deal-bg {
  position: relative;
  background-color: #e7e7e7;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.deal-bg-2 {
  border-radius: 10px;
  margin: 0 30px;
}

@media only screen and (max-width: 479px) {
  .deal-bg-2 {
    margin: 0;
  }
}

.deal-image {
  position: absolute;
  left: auto;
  right: 80px;
  bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-image {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-image {
    right: 0px;
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .deal-image {
    margin: auto;
    width: 95%;
    position: initial;
  }
}

.deal-wrapper {
  width: 50%;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-wrapper {
    width: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-wrapper {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .deal-wrapper {
    margin: auto;
    width: 100%;
    margin: 0 0 50px;
  }
}

.deal-wrapper .category {
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin: 0 0 12px;
  color: #fb5d5d;
}

.deal-wrapper .title {
  font-size: 36px;
  color: #464444;
  font-weight: 700;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-wrapper .title {
    font-size: 24px;
  }
}

.deal-wrapper .deal-timing {
  margin: 21px 0 32px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-wrapper .deal-timing {
    margin: 15px 0 25px 0;
  }
}

.deal-wrapper .deal-timing span.cdown {
  display: inline-block;
  margin-right: 25px;
  background: inherit;
  font-size: 36px;
  font-weight: 800;
  line-height: 1;
  color: #303030;
  width: 60px;
  height: 40px;
  padding-top: 0;
  text-align: center;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .deal-wrapper .deal-timing span.cdown {
    font-size: 24px;
  }
}

.deal-wrapper .deal-timing span.cdown:last-child {
  margin-right: 0;
}

.deal-wrapper .deal-timing span.cdown:last-child::after {
  display: none;
}

.deal-wrapper .deal-timing span.cdown::after {
  position: absolute;
  top: 0;
  right: -20px;
  color: #303030;
  content: ":";
  font-size: 36px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .deal-wrapper .deal-timing span.cdown::after {
    font-size: 24px;
  }
}

.deal-wrapper .deal-timing span {
  display: inline-block;
}

.deal-wrapper .deal-timing span p {
  font-size: 14px;
  text-transform: uppercase;
  color: #303030;
  font-weight: 600;
  margin: 12px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .deal-wrapper a.btn-primary {
    width: 150px;
    height: 50px;
    font-size: 14px;
  }
}

/* Testimonial Section style */
@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
  .testimonial-area.pb-40px {
    padding-bottom: 0px;
  }
}

.testimonial-wrapper .swiper-wrapper {
  padding: 60px 0px;
}

@media only screen and (max-width: 767px) {
  .testimonial-wrapper .swiper-wrapper {
    padding-top: 40px;
  }
}

.testimonial-wrapper .swiper-wrapper .swiper-slide .testi-inner {
  padding: 60px 40px;
  -webkit-box-shadow: 0px 30px 64px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 30px 64px 0px rgba(0, 0, 0, 0.05);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 479px) {
  .testimonial-wrapper .swiper-wrapper .swiper-slide .testi-inner {
    padding: 50px 30px;
  }
}

.testimonial-wrapper .swiper-wrapper .swiper-slide .testi-inner::after {
  position: absolute;
  top: auto;
  left: auto;
  right: 22px;
  bottom: 22px;
  content: "";
  background-image: url(../images/testimonial-image/quate-2.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  width: 49px;
  height: 35px;
}

.testimonial-wrapper .swiper-wrapper .swiper-slide .testi-inner .reating-wrap {
  line-height: 1;
  margin: 0 0 20px;
}

.testimonial-wrapper .swiper-wrapper .swiper-slide .testi-inner .reating-wrap i {
  color: #ffde00;
  font-size: 14px;
  margin-right: 5px;
}

.testimonial-wrapper .swiper-wrapper .swiper-slide .testi-inner .testi-content p {
  font-size: 18px;
  line-height: 30px;
  color: #818790;
  font-style: italic;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 479px) {
  .testimonial-wrapper .swiper-wrapper .swiper-slide .testi-inner .testi-content p {
    font-size: 16px;
    line-height: 28px;
  }
}

.testimonial-wrapper .swiper-wrapper .swiper-slide .testi-inner .testi-author {
  line-height: 1;
  margin: 40px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-wrapper .swiper-wrapper .swiper-slide .testi-inner .testi-author .author-img {
  position: relative;
}

.testimonial-wrapper .swiper-wrapper .swiper-slide .testi-inner .testi-author .author-img::after {
  position: absolute;
  top: -5px;
  left: auto;
  right: -5px;
  height: 22px;
  width: 22px;
  content: "";
  background-image: url(../images/testimonial-image/quate-1.png);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fb5d5d;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.testimonial-wrapper .swiper-wrapper .swiper-slide .testi-inner .testi-author .author-name {
  margin-left: 25px;
}

.testimonial-wrapper .swiper-wrapper .swiper-slide .testi-inner .testi-author .author-name .name {
  color: #39393a;
  font-size: 18px;
  margin: 0 0 3px;
}

.testimonial-wrapper .swiper-wrapper .swiper-slide .testi-inner .testi-author .author-name .title {
  color: #576477;
  font-size: 14px;
  font-weight: 300;
}

.brand-slider {
  border-top: 1px solid #ececec;
  padding-top: 60px;
}

.brand-slider .brand-slider-item img {
  filter: gray;
  -webkit-filter: grayscale(1);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  margin: auto;
  opacity: 0.5;
}

.brand-slider .brand-slider-item:hover img {
  filter: none;
  -webkit-filter: grayscale(0);
  opacity: 1;
}

/* .modal-backdrop {
  background-color: transparent;
} */

.modal {
  overflow: hidden !important;
  padding-right: 0 !important;
}

.filters-drawer {
  overflow: auto;
  padding: 10px;
}

.modal::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.87);
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 100%;
  -webkit-transform: scale(0.04) translateY(9999px);
  transform: scale(0.04) translateY(9999px);
  overflow: hidden;
  z-index: 9;
}

.filters-btn {
  width: 100%;
  background: #fb5d5d;
  padding: 6px;
  color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
}

.modal .modal-overlay {
  overflow: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
}

.modal .modal-dialog {
  top: 0 !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.55s ease, opacity 0.35s ease-in;
  transition: all 0.55s ease, opacity 0.35s ease-in;
  background-color: transparent;
  width: 100%;
  opacity: 0;
  margin: auto;
  z-index: 9999;
}

.modal.show::before {
  -webkit-animation: menu-animation 0.8s ease-out forwards;
  animation: menu-animation 0.8s ease-out forwards;
  -webkit-transform: scale(2) translateY(0);
  transform: scale(2) translateY(0);
}

.modal.show .modal-dialog {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
  opacity: 1;
  top: 50%;
}

.modal.show .close-btn {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
  opacity: 1;
  top: 50px;
}

.modal.popup-login-style .modal-dialog,
.modal.popup-search-style .modal-dialog {
  max-width: 400px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 41px 1px rgba(0, 0, 0, 0.46);
  box-shadow: 0 0 41px 1px rgba(0, 0, 0, 0.46);
}

@media only screen and (max-width: 575px) {

  .modal.popup-login-style .modal-dialog,
  .modal.popup-search-style .modal-dialog {
    max-width: 380px;
  }
}

@media only screen and (max-width: 479px) {

  .modal.popup-login-style .modal-dialog,
  .modal.popup-search-style .modal-dialog {
    max-width: 330px;
  }
}

.modal.popup-login-style .modal-dialog .modal-content,
.modal.popup-search-style .modal-dialog .modal-content {
  background-color: rgba(255, 255, 255, 0.1);
}

.modal.popup-login-style .modal-dialog .modal-content .modal-body,
.modal.popup-search-style .modal-dialog .modal-content .modal-body {
  padding: 40px 20px 45px;
}









.button {
  border-radius: 5px;
  background-color: #fb5d5d;
  padding: 9px 32px;
  color: #fff !important;
  text-align: center;
  font-size: 15px;
  height: 35px;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  transition: all 250ms linear;
  text-decoration: none;
}

.button:hover {
  text-decoration: none;
}

.button a {
  color: #fff !important;
}

.button--google {
  background-color: #fb5d5d;
  ;
  border: 1px solid #fb5d5d;
}

.button--google .icon {
  border-right: 1px solid #fb5d5d;
  margin: 5px;
}

.button--google .icon:after {
  border-right: 1px solid #fb5d5d;
}

.button--google:hover {
  background-color: #fb5d5d;
}


.close-btn {
  position: absolute;
  top: 0;
  right: 50px;
  color: #fff;
  z-index: 9;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 30px;
  background: transparent;
  border: 1px solid #fff;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 0;
}

.close-btn:hover {
  color: #fb5d5d;
  border-color: #fb5d5d;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.login-content h2 {
  font-weight: 700;
  color: #fff;
  font-size: 30px;
  text-align: center;
  margin: 0 0 48px;
}

.login-content h3 {
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 0 29px;
}

.login-content form>input {
  width: 100%;
  background-color: #fff;
  padding: 1px 20px;
  color: #000;
  line-height: 47px;
  border: none;
  border-radius: 5px;
  margin-bottom: 25px;
}

.login-content form>input::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}

.login-content form>input::-moz-input-placeholder {
  color: #000;
  opacity: 1;
}

.login-content form .remember-forget-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 32px;
}

.login-content form .remember-forget-wrap .remember-wrap {
  position: relative;
}

.login-content form .remember-forget-wrap .remember-wrap input {
  position: absolute;
  cursor: pointer;
  z-index: 999;
  width: 20px;
  height: 20px;
}

.login-content form .remember-forget-wrap .remember-wrap p {
  margin: 0px 0 0 30px;
  color: #fff;
  font-size: 14px;
}

.login-content form .remember-forget-wrap .remember-wrap p a {
  color: #fb5d5d;
}

.login-content form .remember-forget-wrap .forget-wrap a {
  font-size: 14px;
  color: #fb5d5d;
}

.login-content form button {
  color: #fff;
  font-weight: 600;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #fb5d5d;
  border-radius: 5px;
  padding: 16px 20px;
  border: none;
  text-transform: uppercase;
  line-height: 1;
}

.login-content form button:hover {
  background-color: #000;
}

.login-content form .member-register {
  margin: 27px 0 0;
}

.login-content form .member-register p {
  color: #999;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  font-size: 14px;
}

.login-content form .member-register p a {
  color: #fff;
  display: inline-block;
  margin-left: 5px;
  line-height: 1;
  padding: 0 0 1px;
  border-bottom: 1px solid #fff;
}

/* .modal-content {
  background-color: rgba(255, 255, 255, 0.1) !important;

} */

.modal-title {
  color: #000;

}

/* .modal-body input {
  background-color: #FFF !important;
  border-radius: 8px !important;
} */

.modal-body img {
  width: -webkit-fill-available !important;
}

.modal .submit-btn {
  position: absolute;
  top: 16px;
  right: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 39px;
  background: #fb5d5d;
  color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 0;
  outline: 0;
  font-size: 20px;
  border-radius: 0 5px 5px 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin: -16px -14px;
}

.modal .submit-btn:hover {
  background-color: #000;
}

.modal h2 {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  margin: 0 0 30px;
}

.modal.fade .modal-dialog {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.modal-2.fade .modal-dialog {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
  -webkit-transition: opacity 0.7s;
  transition: opacity 0.7s;
}

.modal-2.fade.show::before {
  -webkit-animation: menu-animation 0.8s ease-out forwards;
  animation: menu-animation 0.8s ease-out forwards;
  -webkit-transform: scale(2) translateY(0);
  transform: scale(2) translateY(0);
}

.modal-2.fade.show .modal-dialog {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
  opacity: 1;
  top: 50%;
}

.modal-2 h2 {
  color: #000000;
  font-size: 30px;
  text-transform: capitalize;
  line-height: 1;
  font-weight: 600;
  margin: 0 0 18px 0;
}

.footer-2 ul {
  padding-left: 0rem !important;
}

.login-form h1 {
  font-size: 36px;
  text-align: center;
  color: #fb5d5d;
  margin-bottom: 30px;
  font-weight: normal;
}

.login-form h5 {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 0 29px;
}

.login-form .social-icon {
  width: 100%;
  font-size: 20px;
  padding-top: 20px;
  color: #fff;
  text-align: center;
  float: left;
}

.forgot {
  display: flex;
  justify-content: end;
  margin-top: -23px;

}

.forgot a {
  color: #fb5d5d;
}

.remember-forget-wrap input {
  width: 36% !important;
  height: 20px !important;
  margin: 0px 10px 0px -54px !important;
}

.google-btn {
  width: 79%;
}

.register-1 {
  display: flex !important;
  align-items: baseline !important;
  justify-content: space-between;
}

.register-1 a {
  margin: 0px 0px 0px 48px;
  color: #fb5d5d;
}

.login-form {
  background: rgba(255, 255, 255, 0.1);
  width: 450px;
  border-radius: 6px;
  margin: 0 auto;
  display: table;
  padding: 15px 30px 30px;
  box-sizing: border-box;
  height: 90vh;
  margin-top: 10vh;
}

.form-group {
  float: left;
  width: 100%;
  margin: 0 0 15px;
  position: relative;
}

.form-group input {
  background: var(--clr-grey-10) !important;
}

.login-form input {
  width: 100%;
  padding: 5px;
  height: 40px;
  border-radius: 7px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 14px;
  padding-left: 40px;
}

.login-form .form-group .input-icon {
  font-size: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  height: 100%;
  padding-left: 15px;
  color: #666;
}

.remember-forget-wrap {
  font-size: 14px;
  color: #fb5d5d;
}


.login-form .login-btn {
  border-radius: 5px;
  width: 100%;
  background-color: #fb5d5d;
  padding: 9px 32px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  height: 35px;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
}

.login-form .reset-psw {
  float: left;
  width: 100%;
  text-decoration: none;
  color: #1017e3;
  font-size: 14px;
  text-align: center;
  margin-top: 11px;
}

.login-form .social-icon button {
  font-size: 20px;
  color: white;
  height: 50px;
  width: 50px;
  background: #1017e3;
  border-radius: 60%;
  margin: 0px 10px;
  border: none;
  cursor: pointer;
}

.login-form button:hover {
  opacity: 0.9;
}

.login-form .seperator {
  float: left;
  width: 100%;
  border-top: 1px solid #ccc;
  text-align: center;
  margin: 15px 0 0;
  padding-bottom: 12px;
}

.login-form .seperator b {
  width: 40px;
  height: 40px;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  display: inline-block;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  position: relative;
  top: -22px;
  z-index: 1;
}

.login-form p {
  float: left;
  width: 73%;
  font-size: 16px;
  margin: -28px 0px 0px 35px;
}

.register-pd {
  padding: 4rem 0rem;
  min-height: 90vh;
}

@media screen and (max-width:767px) {
  .login-form {
    width: 100%;
    padding: 15px 0px 30px;
    height: 90vh;
  }

  .register-pd {
    padding: 2rem 1rem;
    min-height: 40vh;

  }

  .add-to-cart-btn {
    padding: 5px 10px;
    font-size: 12px;
  }



  /* .page-100 {
    padding: 2rem 0rem;
  } */

  /* .register-1 p {
    display: flex !important;
    align-items: baseline !important;
    margin: 1px 5rem -11px 1px !important;
  } */

  /* .remember-forget-wrap input {
    width: 36% !important;
    height: 20px !important;
    margin: 0px 9px 0px -61px !important;
  } */
}

.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

/* @media only screen and (max-width: 600px) {
  .register-1 p {
    align-items: baseline !important;
    display: flex !important;
    margin: 2px 18px 4px -2px !important;
  }
} */

/* @media only screen and (max-width: 600px) and (min-width: 300px) {
  .register-1 p {
    display: flex !important;
    align-items: baseline !important;
    margin: -2px 19px 5px -32px !important;
  }

  .remember-forget-wrap input {
    width: 36% !important;
    height: 20px !important;
    margin: -1px 6px 6px -23px !important;
  }

  .forgot a {
    margin: 0px 2px 0px 0px;
  }
} */

@media only screen and (max-width: 430px) {
  .register-1 p {
    align-items: baseline !important;
    display: flex !important;
    margin: 4px 27px 7px 6px !important;
  }

  .remember-forget-wrap input {
    height: 20px !important;
    /* margin: -2px 19px 5px -36px !important; */
    width: 36% !important;
  }

  .register-1 a {
    color: #fb5d5d;
    margin: 0px 0px 0px 6px;
  }

  .forgot a {
    margin: -5px -6px 0px 0px;
  }
}

/* @media only screen and (max-width: 630px){
  .register-1 a {
    margin: 0px 0px 0px 19rem;
    color: #fb5d5d;
}
.register-1 p {
  display: flex !important;
  align-items: baseline !important;
  margin: 1px 5rem -24px 8px !important;
}
.remember-forget-wrap input {
  width: 36% !important;
  height: 20px !important;
  margin: 0px 9px 0px -60px !important;
}
} */

/* Avatar image */
img.avatar {
  width: 40px;
  lenght: 40px;
  height: 40px;
  border-radius: 50%;
}

@media only screen and (max-width: 479px) {
  .modal-2 h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 479px) {
  .modal-2 .product-details-content .pro-details-quality .pro-details-cart .add-cart {
    font-size: 12px;
  }
}

.modal-open .modal-2 {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

@-webkit-keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }

  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    transition: ease-out;
  }

  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }

  61% {
    opacity: 1;
    -webkit-transform: scale(0.04) translateY(0px);
    transform: scale(0.04) translateY(0px);
  }

  99.9% {
    opacity: 1;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(2) translateY(0px);
    transform: scale(2) translateY(0px);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

@keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }

  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    transition: ease-out;
  }

  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }

  61% {
    opacity: 1;
    -webkit-transform: scale(0.04) translateY(0px);
    transform: scale(0.04) translateY(0px);
  }

  99.9% {
    opacity: 1;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(2) translateY(0px);
    transform: scale(2) translateY(0px);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}