/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(7, 97%, 65%);
  --clr-primary-2: hsl(7, 97%, 29%);
  --clr-primary-3: hsl(7, 97%, 37%);
  --clr-primary-4: hsl(7, 97%, 45%);
  /* primary/main color */
  --clr-primary-5: hsl(7, 57%, 52%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(7, 57%, 60%);
  --clr-primary-7: hsl(7, 57%, 67%);
  --clr-primary-8: hsl(7, 57%, 74%);
  --clr-primary-9: hsl(7, 57%, 81%);
  --clr-primary-10: hsl(7, 57%, 88%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-orange: hsl(23, 95%, 52%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Montserrat;
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

hr {
  border: none;
  border-top: 1px solid var(--clr-grey-8);
}

h1,
h2,
h3,
h4,
h5 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.mt-6 {
  margin-top: 60px;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-3);
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1rem;
  }

  body {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

/*  global classes */

/* section */
.section {
  padding: 0rem 0;
  margin-top: 60px;
}

.section-center {
  width: 100vw;
  margin: 0 auto;
  margin-top: 60px;
  max-width: var(--max-width);
}

.categories-section h1 {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
}

.category-bg-image-1 {
  background-image: url("https://ik.imagekit.io/i4uldqtxs/jeans.jpg?updatedAt=1715157565436");
  background-size: cover;
}

.category-bg-image-2 {
  background-image: url("https://ik.imagekit.io/i4uldqtxs/shirts.jpg?updatedAt=1715157564992");
  background-size: cover;
}

.category-bg-image-3 {
  background-image: url("https://ik.imagekit.io/i4uldqtxs/new-aarivals.jpg?updatedAt=1715157565380");
  background-size: cover;
}

.category-bg-image-4 {
  background-image: url("https://ik.imagekit.io/i4uldqtxs/oversized.jpg?updatedAt=1715157564613");
  background-size: cover;
}

.categories-section p {
  padding: 150px 15px;
  color: #000;
  font-size: 24px;
  border-radius: 15px;
  text-align: center;
}

.category-slider .slick-initialized .slick-slide {
  padding: 5px !important;
}

.review-product {
  padding: 10px 10px;
  font-weight: 500;
  border-radius: 9px;
  font-size: 16px;
  background: #fb5d5d;
  color: #fff;
  width: "fit-content";
}

.review-btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.review-btn {
  padding: 7px 10px;
  font-weight: 500;
  border-radius: 9px;
  font-size: 16px;
  background: #fb5d5d;
  color: #fff;
  width: "fit-content";
}

@media only screen and (max-width: 600px) {
  .section-center {
    padding: 20px;
    width: 100%;
  }

  .add-cart {
    display: flex !important;
  }

  .submit-btn {
    width: 100%;
  }

  .category-slider .slick-slide img {
    border-radius: 30px;
  }

  .drawer {
    width: 350px !important;
    /* overflow: auto !important; */
  }

  .order-page-pd {
    padding: 15px;
  }

  .fill-it-btn-mobile {
    width: 80% !important;
  }
  .sign-in-mobile {
    position: relative;
    top: -3px;
  }
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}

@media screen and (min-width: 990px) {
  .pe-7s-menu {
    display: none !important;
  }
}

.text-center {
  text-align: center;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.form-control-2 {
  border: 1px solid silver;
  margin-bottom: 10px;
  padding: 2px;
  border-radius: 5px;
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 0rem;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: var(--clr-primary-5);
  animation: spinner 0.6s linear infinite;
}

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-5);
  color: var(--clr-primary-10);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 400;
  transition: var(--transition);
  font-size: 0.875rem;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
  border-color: transparent;
}

.btn:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-7);
}

/* .title {
  text-align: center;
} */

.title .underline {
  width: 6rem;
  height: 0.25rem;
  background: #49a6e9;
  background: var(--clr-primary-5);
  margin-left: auto;
  margin-right: auto;
}

.page-100 {
  min-height: calc(100vh - 10rem);
  padding: 5rem 0;
}

.page {
  min-height: calc(100vh - (20vh + 10rem));
}

.btn:disabled,
.btn[disabled] {
  background: var(--clr-primary-8);
}

.btn:disabled:hover,
.btn[disabled]:hover {
  color: var(--clr-primary-10);
  cursor: not-allowed;
}

.cart-btn {
  font-size: 16px !important;
  text-decoration: none;
  color: #000 !important;
  margin-top: 17px;
}

.cart-btn a {
  text-decoration: none;
}

.auth-btn {
  font-size: 16px !important;
  text-decoration: none;
  font-weight: 600;
  color: #000 !important;
}

/* new css */

.heading {
  display: flex;
  justify-content: center;
  padding: 32px 10px 32px 10px;
}

.contact ul {
  padding: 10px 10px 10px 1px;
}

.items-card {
  margin: 6rem !important;
}

.load-more {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.btn {
  border-radius: 25px !important;
  display: flex !important;
  /* justify-content: flex-end !important; */
}

.fill-it {
  /* width:100% !important; */
  border-radius: 10px !important;
  display: inline-block !important;
}

.new {
  padding: 48px 5px 10px 12px;
}

.category {
  /* display: flex;
  justify-content: center; */
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.category img {
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 300px;
}

.category .item-disc {
  position: absolute;
  top: auto;
  padding: 0px 20px 0px 20px;
  bottom: 10px;
  width: 100%;
}

.category .item-disc .shop-link {
  font-size: 10px;
  width: 114px;
  height: 35px;
  margin: auto;
}

.category .item-disc .title-2 {
  color: #fff;
  font-size: 20px;
  line-height: 34px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0rem;
}

.mobile-menu ul li {
  display: block;
  padding: 10px 0px;
  text-transform: capitalize;
  color: #474747;
  border-top: 1px solid #eee;
  font-size: 16px;
  font-weight: 600;
}

.offcanvas-social ul li {
  display: inline-block;
  margin: 0 25px 0px 0;
  line-height: 44px;
  padding: 2px;
}

.offcanvas-close {
  font-size: x-large;
  padding: 0px 0px 0px 7px;
}

.carthead {
  display: flex;
  justify-content: space-between;
}

.image {
  /* max-width: 100%;
  border: 1px solid #ebebeb; */
}

.minicart-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ebebeb;
}

.drawer {
  width: 350px !important;
  /* overflow: auto !important; */
}

.cart-content {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 calc(100% - 150px);
  flex: 1 0 calc(100% - 150px);
  padding-left: 15px;
}

.image {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 75px;
  flex: 1 0 75px;
}

.cart-title {
  color: #000;
  font-weight: 400;
}

.cart-content span {
  font-size: 14px;
  display: block;
  margin-top: 10px;
  color: #fb5d5d;
}

.title {
  padding: 5px 0px 5px 0px;
}

.empty {
  text-align: center;
  margin-top: 1rem;
}

.cart_info {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: baseline;
}

.proceed-btn {
  background-color: #fb5d5d !important;
  color: #fff !important;
  position: absolute;
  zoom: 1.5;
  bottom: 0;
  left: 20px;
  right: 20px;
  margin-top: 30px;
  padding: 5px 20px;
  font-size: 10px !important;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cart_text {
  display: flex;
  justify-content: space-between;
}

.shipping-btn {
  background-color: #fb5d5d !important;
  color: #fff !important;
}

.dashboard {
  display: flex;
  height: 100vh;
  width: 100%;
}

.sidebar {
  width: 20%;
  background-color: #333;
  color: #fff;
}

.submit-btn {
  background-color: #fb5d5d !important;
  color: #fff !important;
}

.profile-section {
  display: flex;
  gap: 40px;
  align-items: flex-start;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px 30px;
  cursor: pointer;
}

.content-profile {
  width: 80%;
  padding: 20px;
  height: 100vh;
  overflow: auto;
}

.stock-btn {
  border: 1px solid;
  border-radius: 6px;
  padding: 1px 5px;
}

.out-of-stock {
  border: 1px solid gray;
  border-radius: 6px;
  padding: 1px 5px;
}

.selected-size {
  border: 1px solid #fd3d6b;
  color: #fd3d6b !important;
  border-radius: 6px;
  padding: 1px 5px;
}

.selected-size:focus {
  outline: 1px solid #fd3d6b !important;
}

/* Style active link in the sidebar */
.sidebar ul li.active {
  background-color: #555;
}

.empty h2 {
  margin-bottom: 17px !important;
}

.add-cart {
  display: flex;
  justify-content: space-between !important;
}

.add {
  display: block;
  padding: 5px 15px;
  line-height: 24px;
  background: linear-gradient(-155deg, #fd3d6b 0%, #fd7863 98%, #f3dfe0 100%);
  /* border: 0; */
  border-radius: 30px;
  font-size: 12px;
  font-weight: 500;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: #fff;
  text-transform: uppercase;
  overflow: hidden;
}

.mobile-user-avatar {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 15px;
}

.mobile-user-avatar {
  padding-left: 1rem !important;
}

.mobile-user-avatar p {
  color: black !important;
  margin-bottom: 0px !important;
  font-weight: 600;
}

.size {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.buy-now {
  border: 1px solid #fd3d6b;
  color: #fd3d6b !important;
  border-radius: 6px;
  border-radius: 19px;
  background-color: #fff !important;
  padding: 5px 15px;
}

.buy-now a {
  color: #fd3d6b !important;
}

.order-table-container {
  overflow-x: auto;
  max-width: 80%;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.order-table th,
.order-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.order-table th {
  background-color: #f2f2f2;
}

.shipping input {
  zoom: 0.4;
  margin: 3px 0px 15px 0px;
}

.shipping label {
  padding: 3px 6px 0px 0px;
}

.form-check-input:checked[type="radio"] {
  padding: 10px !important;
}
.form-check-input[type="radio"] {
  padding: 10px !important;
}

.other-pages {
  margin-bottom: 10px !important;
}

@media only screen and (max-width: 767px) {
  .title {
    padding: 5px 0px 5px 0px;
  }

  .add-cart {
    display: block;
  }

  .category img {
    height: 150px;
  }

  .category .item-disc .title-2 {
    font-size: 14px;
    line-height: 24px;
  }

  .category .item-disc {
    padding: 0px 5px 0px 5px;
  }

  .category .item-disc .shop-link {
    width: 85%;
    font-size: 8px;
    height: 26px;
    padding: 0px 3px;
  }

  .padding-zero {
    padding: 0px !important;
  }

  .scrollable-row {
    overflow-x: auto;
    white-space: nowrap;
  }

  .scrollable-row .col-4 {
    display: inline-block;
    float: none; /* Override Bootstrap's float styles */
  }

  .dashboard {
    display: block;
  }

  .sidebar {
    width: 100%;
  }

  .content-profile {
    width: 100%;
    height: 85vh;
  }

  .profile-section {
    display: block;
  }

  .profile-img {
    margin-top: 30px !important;
  }
}

/* @media only screen and (max-width:400px) {
  .buy-now {
    width: 100%;
  }

} */
/* @media only screen and (max-width: 1000px) {
    .size {
      padding: 0px 18rem 0px 0px;
    }
    
  } */
