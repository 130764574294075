.slider-content {
    position: absolute;
    left: 12%;
    top: 25%;
    width: 5%;
}

.slider-content h1 {
    font-family: "'Nunito Sans'", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 50px;
}

.slider-content p {
    font-family: "'Nunito Sans'", sans-serif;
    font-weight: 400;
    font-size: 22px;
    margin-top: 30px;
}

.slider-content button {
    font-family: "'Nunito Sans'", sans-serif;
    margin-top: 30px;
    font-size: 18px;
    line-height: 56px;
    height: 56px;
    padding: 0 32px;
    background: #6a7964;
    color: #fff;
    border: none;
}

.slider-content button a:hover {
    color: #000 !important;
}

.category-bg1 {
    background: url(../../assets/img/bg/bg1.jpg);
    background-repeat: round;
}

.category-bg2 {
    background: url(../../assets/img/bg/bg2.jpg);
    height: 550px;
}

.adds-bg1 {
    background: url(../../assets/img/bg/bg3.jpg);
    background-repeat: round;
}

.adds-bg2 {
    background: url(../../assets/img/bg/bg4.jpg);
    height: 550px;
    background-repeat: round;
    background-position: inherit;
}

.adds-section {
    padding: 50px;
}

.adds-section label {
    font-size: 14px;
    line-height: 24px;
    color: #999999;
    text-transform: uppercase;
    display: flex;
}

.adds-section h3 {
    text-transform: uppercase;
    line-height: 48px;
    font-weight: 400;
    margin-bottom: 17px;
    font-size: 48px;
    margin-top: 60px;
}

.adds-section p {
    font-size: 14px;
    color: #666666;
    margin-bottom: 0;
    font-weight: 400;
    width: 230px;
}

.adds-section button {
    font-size: 16px;
    line-height: 45px;
    height: 45px;
    padding: 0 20px;
    background: #6a7964;
    border-radius: 0;
    position: relative;
    z-index: 9;
    color: #ffffff;
    border: 0;
    font-weight: 600;
    margin-top: 50px;
}

.products-header {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    margin-bottom: 30px;
}

.products-header h1 {
    font-size: 28px;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 700;
    color: #6a7964;
    display: inline-block;
    margin-bottom: 0;
    font-family: "'Nunito Sans'", sans-serif;
}

.products-header ul {
    display: flex;
    gap: 35px;
}

.products-header ul li {
    font-size: 16px;
    line-height: 18px;
    color: #999999;
    display: inline-block;
    font-family: "'Nunito Sans'", sans-serif;
}

.product-section {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    gap: 20px;
}



.article-section {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 20px;
}

.article-section h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 0;
    font-family: "'Nunito Sans'", sans-serif;
    margin-top: 15px;
}

.article-section ul {
    display: flex;
    padding-left: 0px;
    margin-top: 20px;
}

.article-section ul li:first-child {
    border-right: 2px solid #b8b8b8;
}

.article-section ul li {
    margin-right: 17px;
    padding-right: 17px;
    line-height: 18px;
    position: relative;
    font-size: 16px;
    line-height: 18px;
    display: inline-block;
    color: #000000;
    font-weight: 400;
    font-family: "'Nunito Sans'", sans-serif;
}

.article-section ul li span {
    font-size: 16px;
    line-height: 18px;
    display: inline-block;
    color: #000000;
    font-weight: 600;
    font-family: "'Nunito Sans'", sans-serif;
}

.product-section h5 {
    font-weight: 700;
    line-height: 24px;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 5px;
    font-family: "'Nunito Sans'", sans-serif;
}

.product-section h6 {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 5px;
    font-family: "'Nunito Sans'", sans-serif;
    color: red;
}

.product-section h6 span {
    text-decoration: line-through;
    font-size: 16px;
    color: #666666;
    margin-left: 10px;
}

.category-section h3 {
    font-size: 36px;
    text-transform: uppercase;
    line-height: 48px;
    font-weight: 400;
    margin-bottom: 17px;
}

.category-section {
    padding: 50px;
}

.category-section p {
    font-size: 15px;
    color: #666666;
    margin-bottom: 0;
    font-weight: 400;
}

.category-cols {
    display: grid;
    grid-template-columns: 65% 35%;
    gap: 30px;
}

.adds-cols {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 30px;
}

.category-section button {
    font-size: 16px;
    line-height: 45px;
    height: 45px;
    padding: 0 20px;
    background: #6a7964;
    border-radius: 0;
    position: relative;
    z-index: 9;
    color: #ffffff;
    border: 0;
    font-weight: 600;
    margin-top: 200px;
}

.slider-content button:hover {
    background: #fff;
    color: #000 !important;
}

.slider-section .slick-dots {
    position: absolute;
    bottom: 57px;
}

.slider-section .slick-dots li button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 0;
    padding: 0;
    background: #b8b8b8;
    text-indent: -99999px;
}

/* .cart-btn-wrapper {
    width: 400px;
} */

.slider-section .slick-dots li.slick-active button {
    background: #000;
}

.slider-section {
    margin-bottom: 45px;
}

.hide-in-desktop {
    display: none;
}

.product-btn-section {
    margin-top: 20px;
}

.add-to-card-details-page {
    margin-right: 10px;
}

@media only screen and (max-width: 400px) {

    .banner-mobile {
        margin-top: 60px;
    }

    /* .product-btn-section {
        display: block;
    } */

    .product-border {
        width: 81%;
        border: 1px solid rgb(206, 212, 218);
        padding: 10px;
        border-radius: 8px;
    }

    /* 
    .add-to-cart-btn: {
        margin-bottom: 10px;
        width: 100%;
    } */
    .size {
        margin: -2px 0rem 0px 0px;
        display: flex;
        justify-content: space-between;
        /* padding: 0px 10rem 0px 0px; */
        margin-bottom: 10px;
    }

    .add-to-cart-btn {
        width: 100%;
        /* margin-bottom: 10px; */
        margin: 0px 7px 10px 0px;
    }

    .slider-content {
        width: 10%;
    }

    .slider-content h1 {
        font-size: 22px;
        margin-top: 20px;
    }

    .slider-content p {
        margin-top: 10px;
    }

    .slider-content button {
        line-height: 40px;
        height: 50px;
        padding: 0 15px;
        margin-top: 10px;
    }

    .hide-in-mobile {
        display: none;
    }

    .hide-in-desktop {
        display: block;
    }

    .dropdown-list ul {
        padding-left: 0rem;
    }

    .dropdown-list ul li {
        margin-bottom: 5px;
        font-weight: 700;
        line-height: 24px;
        font-size: 16px;
        margin-bottom: 5px;
        font-family: "'Nunito Sans'", sans-serif;
    }

    .product-section h6 {
        margin-bottom: 20px;
    }

    .profile-btn {
        margin-left: 20px;
    }

    .nav-header img {
        margin-left: 0px;
    }

    .cart-btn {
        margin-bottom: 0rem;
        margin-top: 0px;
    }

    .cart-btn-wrapper {
        width: 27% !important;
    }

    .products-container {
        grid-template-columns: 50% 50%;
        gap: 1rem 0.5rem;
    }

    /* .cart-btn-wrapper {
        margin: 0px 4rem !important;
    } */
    .buy-now {
        width: 100%;
    }
}

.add-to-cart-btn {
    padding: 4px 10px;
    zoom:1.3;
    border-radius: 9px;
    font-size: 14px;
    background: #fb5d5d;
    color: #fff;
}

@media only screen and (max-width: 800px) {
    .product-border {
        width: 100%;
        border: 1px solid rgb(206, 212, 218);
        /* padding: 12px; */
        border-radius: 8px;
        margin-bottom: 10px;
    }

    .mobile-margin-top-zero {
        margin-top: 0px !important;
    }

    .mobile-margin-top {
        margin-top: 10px !important;
    }

    .banner-mobile {
        margin-top: 60px;
    }

    .size {
        /* margin: 0px 8rem 0px 0px; */
        display: flex;
        justify-content: space-between;
        /* padding: 0px 10rem 0px 0px; */
        margin-bottom: 10px;
    }

  

    .cart-btn {
        margin-bottom: 0rem;
        margin-top: 0px;
    }

    .nav-header img {
        margin-left: 0px;
    }

    .products-container {
        grid-template-columns: 50% 50%;
        gap: 1rem 0.5rem;
    }

    .cart-btn-wrapper {
        width: 27% !important;
    }

    .dropdown-list ul {
        padding-left: 0rem;
    }

    .dropdown-list ul li {
        margin-bottom: 5px;
        font-weight: 700;
        line-height: 24px;
        font-size: 16px;
        margin-bottom: 5px;
        color: #999999;
        font-family: "'Nunito Sans'", sans-serif;
    }

    .product-section h6 {
        margin-bottom: 20px;
    }

    /* .hide-in-desktop {
        display: block;
    } */

    /* .hide-in-mobile {
        display: none;
    } */

    .slider-content {
        width: 5%;
        text-align: left;
        top: 18%;
        font-size: 14px;
    }

    .slider-content h1 {
        font-size: 20px;
        margin-top: 20px;
    }

    .slider-content p {
        margin-top: 10px;
        font-size: 18px;
    }

    .slider-content button {
        line-height: 40px;
        height: 50px;
        padding: 0 15px;
        margin-top: 10px;
    }

    .category-cols {
        display: block;
    }

    .product-section {
        display: block;
    }

    .adds-cols {
        display: block;
    }

    .article-section {
        display: block;
    }

    .banner-mobile img {
        height: 330px;
    }

    .profile-btn {
        margin-left: 20px;
    }

    /* .cart-btn-wrapper {
        margin: 0px 4rem !important;
    } */
}